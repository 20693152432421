import { Span, Div } from "@tblabs/truffle";

export class DeviceMenuButton extends Div
{
    constructor(icon, label)
    {
        super();
        this.Class("DeviceMenuButton");

        this.Append(
            new Span(icon).Class("icon"),
            new Span(label).Class("label")
        );
    }
}
