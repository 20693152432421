import { Server } from "../Services/Server";
import { Link } from "@tblabs/truffle";


export class LogoutButton extends Link
{
    constructor(_server: Server)
    {
        super("🏠 Logout");

        this.NoDecorationOnHover().Color("#fff").FloatRight().CursorPointer()
            .OnClick(() =>
            {
                _server.Logout();
                window.location.hash = "login";
            });
    }
}
