import { ModalWindow, Span } from "@tblabs/truffle";
import { Device } from "../Services/Device";
import { DeviceMenuButton } from "../Components/DeviceMenuButton";
import { SetupWindow } from "./../Pages/Shell/SetupWindow";
import { TerminalWindow } from "./../Pages/Shell/TerminalWindow";
import { PasswordEditWindow } from "./PasswordEditWindow";
import { AdminWatchWindow } from "./AdminWatchWindow";
import { LedControlWindow } from "./LedControlWindow";
import { PasswordSanitizer } from "../Components/PasswordSanitizer";


export class DeviceMenuWindow extends ModalWindow
{
    private status = new Span("...");

    constructor(device: Device)
    {
        super(device.profile.Id, true, true);
        this.Width(94, "%").MaxWidth(600);

        this.Shutter.Blur(0.8)
            .OnClick(() => this.CloseWindow())

        this.content.TextAlignCenter()
            .Append(
                new DeviceMenuButton("📺", "Watch")
                    .OnClick(() =>
                        window.open(
                            (process.env.WATCHER_WATCH_PAGE || "")
                                .replace("{server}", device.Server.Id)
                                .replace("{password}", PasswordSanitizer.Sanitize(device.profile.Password.value)))),
                new DeviceMenuButton("🐓", "Admin")
                    .OnClick(() =>
                        new AdminWatchWindow(device)),
                new DeviceMenuButton("🚀", "Launch")
                    .OnClick(async () =>
                        this.DoAsync(
                            "🚀 Regenerating password...",
                            async () => await device.RegeneratePasswordAndCopySMSMessage(),
                            "🚀 SMS copied to clipboard ✔")),
                new DeviceMenuButton("🔑", "Lock")
                    .OnClick(() =>
                        this.DoAsync(
                            "🔑 Locking...",
                            async () => await device.ResetPassword(),
                            "🔑 Locked ✔")),
                new DeviceMenuButton("🎲", "Regenerate")
                    .OnClick(() =>
                        this.DoAsync(
                            "🎲 Regenerating password...",
                            async () => await device.RegeneratePassword(),
                            "🎲 New password set ✔")),
                new DeviceMenuButton("📝", "Edit")
                    .OnClick(() => new PasswordEditWindow(device)),
                new DeviceMenuButton("📧", "SMS")
                    .OnClick(() =>
                        this.DoAsync(
                            "📧 Generating SMS...",
                            async () => await device.GenerateSMSAndCopyToClipboard(),
                            "📧 Copied to clipboard ✔")),
                new DeviceMenuButton("💌", "Letter")
                    .OnClick(() =>
                        this.DoAsync(
                            "💌 Generating letter...",
                            async () => await device.GenerateLetterAndCopyToClipboard(),
                            "💌 Copied to clipboard ✔")),
                new DeviceMenuButton("🐌", "Shell")
                    .OnClick(() => device.Server.IsConnected ? new TerminalWindow(device) : alert("Panel is not connected to the server. Refresh page.")),
                new DeviceMenuButton("📡", "Setup")
                    .OnClick(() => new SetupWindow(device)),
                new DeviceMenuButton("💾", "Storage")
                    .OnClick(() => window.open(process.env.DROPBOX_PAGE + '/' + device.profile.Password.value, '_blank')),
                new DeviceMenuButton("💡", "Led ")
                    .OnClick(() => new LedControlWindow(device)),
                new DeviceMenuButton("🧨", "Kill")
                    .OnClick(() =>
                        this.MakeSure(`Kill ${device.profile.Id}?`,
                            () => this.Do(
                                "🧨 Killing device...",
                                () => device.Kill(),
                                "🧨 Kill command sent ✔"))),
                new DeviceMenuButton("⚡", "Reboot")
                    .OnClick(() =>
                        this.MakeSure(`Reboot ${device.profile.Id}?`,
                            () => this.Do(
                                "⚡ Rebooting...",
                                () => device.Reboot(),
                                "⚡ Reboot command sent ✔")))
            );
        this.footer.TextAlignCenter().Append(
            this.status
        )
        this.OpenWindow();
    }

    private async DoAsync(before: string, callback, after: string): Promise<void>
    {
        try
        {
            this.status.Text(before).Color("#222");
            await callback();
            this.status.Text(after);
        }
        catch (ex)
        {
            this.status.Text(ex.message).Color("red");
        }
    }

    private Do(before: string, callback, after: string): void
    {
        try
        {
            this.status.Text(before).Color("#222");
            callback();
            this.status.Text(after);
        }
        catch (ex)
        {
            this.status.Text(ex.message).Color("red");
        }
    }

    private MakeSure(label, callback)
    {
        if (confirm(label))
            callback();
    }
}
