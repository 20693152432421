import { Ref, Select, SelectOption } from "@tblabs/truffle";
import { Storage } from "./Storage";


export class CommandSelector extends Select<string>
{
    constructor(selected: Ref<string>, _storage: Storage)
    {
        const selectorOptions = _storage.Commands.map(x => new SelectOption(`${x.Label.value} (${x.Command.value})`, x.Command.value));
        super(selected, selectorOptions);

        _storage.CommandsCollection.OnChange(items =>
        {
            const selectorOptions = _storage.Commands.map(x => new SelectOption(`${x.Label.value} (${x.Command.value})`, x.Command.value));
            this.LoadOptions(selectorOptions);
        });
    }
}
