import { Collection } from "@tblabs/truffle";
import { RawCommandEntry } from "./RawCommandEntry";
import { CommandEntry } from "./CommandEntry";

export class Storage
{
    private commands = new Collection<CommandEntry>([
        new CommandEntry("Intro", "(cd /home/pi && ./intro.sh)"),
        new CommandEntry("Current working directory", "pwd"),
        new CommandEntry("List directory", "ls -lh"),
        new CommandEntry("Network config", "cat /etc/wpa_supplicant/wpa_supplicant.conf"),
        new CommandEntry("Host", "hostname && hostname -I"),
        new CommandEntry("Temperature", "vcgencmd measure_temp"),
        new CommandEntry("Camera software version", "cat version.txt"),
        new CommandEntry("JS processes", "ps -aux | grep js"),
        new CommandEntry("Timers", "sudo systemctl list-timers --all"),
        new CommandEntry("Disc usage", "df -h"),
        new CommandEntry("Wifi connected", "iwgetid"),
        new CommandEntry("Hosts", "cat /etc/hosts"),
        new CommandEntry("Hostname", "cat /etc/hostname"),
        new CommandEntry("Install update", "curl http://apps.specteam.pl/app/camera/{version} --output update.zip && unzip -o update.zip -d . && sudo chmod +x $(ls *.sh) && sudo ./install-update.sh && sudo bash -c 'echo ${v} > version.txt' && rm update.zip -f"),
    ]);

    constructor()
    {
        const rawCommands: RawCommandEntry[] = JSON.parse(window.localStorage.getItem("commands") || "[]");

        rawCommands.forEach(c => this.commands.Add(new CommandEntry(c.Label, c.Command)));
    }

    public Add(label: string, command: string): void
    {
        this.commands.Add(new CommandEntry(label, command));

        this.Save();
    }

    public Remove(entry: CommandEntry)
    {
        this.commands.Remove(entry);

        this.Save();
    }

    public Save(): void
    {
        window.localStorage.setItem("commands",
            JSON.stringify(this.Commands.map(x => new RawCommandEntry(x.Label.value, x.Command.value))));
    }

    public get Commands(): CommandEntry[]
    {
        return this.commands.Items;
    }

    public get CommandsCollection(): Collection<CommandEntry>
    {
        return this.commands;
    }
}
