"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectOption = void 0;
var SelectOption = /** @class */ (function () {
    function SelectOption(Text, Value) {
        this.Text = Text;
        this.Value = Value;
        if (this.Value === undefined)
            this.Value = this.Text;
    }
    return SelectOption;
}());
exports.SelectOption = SelectOption;
