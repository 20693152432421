import { Ref, Select, SelectOption } from "@tblabs/truffle";
import { Filter } from "../Models/Filter";


export class FilterSelect extends Select<Filter>
{
    constructor(filter: Ref<Filter>)
    {
        super(filter, [
            new SelectOption("🌎 All", Filter.All),
            new SelectOption("🎵 Unlocked", Filter.Unlocked),
            new SelectOption("👀 Online", Filter.Online),
            new SelectOption("🍦 Cold", Filter.Cold),
            new SelectOption("💎 Priv", Filter.Priv),
            new SelectOption("✈️ Rental", Filter.Rental),
            new SelectOption("🏠 Locked", Filter.Locked),
            new SelectOption("🥔 Offline", Filter.Offline),
        ]);

        this.WidthAuto();
    }
}
