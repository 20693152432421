import { Div, Link, Ref } from "@tblabs/truffle";
import { CommandSelector } from "./CommandSelector";
import { Storage } from "./Storage";
import { CommandsListWindow } from "./CommandsListWindow";

export class CommandManager extends Div
{
    constructor(_storage: Storage)
    {
        super();
        this.MarginTop(8)

        const rapidCommand = new Ref<string>("");

        const savedCommandsSelector = new CommandSelector(rapidCommand, _storage)
            .OnClick(() => this.onSelectCallback?.(rapidCommand.value));

        this.Append(

            savedCommandsSelector.Width(75, "%"),

            new Link("🎬 Edit").NoDecorationOnHover().MarginLeft(12)
                .OnClick(() => new CommandsListWindow(_storage).OpenWindow())
        );
    }

    private onSelectCallback: any;

    public WhenCommandSelected(callback)
    {
        this.onSelectCallback = callback;
        return this;
    }
}
