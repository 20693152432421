import { Span, Div } from "@tblabs/truffle";
import { Device } from "../Services/Device";
import { DeviceMenuWindow } from "./../Windows/DeviceMenuWindow";
import { PasswordLabel } from "./PasswordLabel";
import { HotspotIcon } from "./HotspotIcon";
import { DeviceConnectionStatusIcon } from "./DeviceConnectionStatusIcon";
import { PasswordSanitizer } from "./PasswordSanitizer";
import { Base64Image } from "./Base64Image";


export class DeviceBox extends Div
{
    constructor(device: Device)
    {
        super();
        this.Class("DeviceBox");

        const { Id, Password, ConnectionStatus: connectionStatus, WatchersCount, Picture, PicturesCount, RebootsCount } = device.profile;

        if (device.IsAtHome)
        {
            this.Background("#85e78575")
        }

        const pic = new Base64Image(Picture).Class("image")
            .OnClick(() => new DeviceMenuWindow(device))

        this.Append(
            new DeviceConnectionStatusIcon(connectionStatus, device.IsHalted),
            new Span(Id).Class("id").CursorPointer()
                .OnClick(() =>
                {
                    window.open(
                        (process.env.WATCHER_WATCH_PAGE || "")
                            .replace("{server}", device.Server.Id)
                            .replace("{password}", PasswordSanitizer.Sanitize(device.profile.Password.value))
                    )
                }),
            new HotspotIcon(device.Hotspot, connectionStatus),
            new Div().Append(
                new PasswordLabel(Password),
            ),
            new Span("👀" + WatchersCount.toString()).Class("watchers"),
            new Span("📷" + PicturesCount.toString() + " ⚡" + RebootsCount.toString()).Class("pictures-count"),
            new Div().Class("picture-box")
                .Append(
                    pic,
                    new Span(device.PictureQuality).Class("quality"),
                    new Span(device.LastPictureTime).Class("time"),
                    new Span(device.LastPictureSize).Class("image-size"),
                    new Span("↻").Class("refresh-btn")
                        .OnClick(() => device.GetLastPicture()),
                )
        );
    }
}
