import { Button, Div, JsonViewer, LabeledNumberInput, Line, ModalWindow, Ref, Span } from "@tblabs/truffle";
import { Device } from "../../Services/Device";

export class SetupWindow extends ModalWindow
{
    constructor(device: Device)
    {
        super("📡 " + device.profile.Id + " Config & Setup");
        this.WidthPercent(96).HeightPercent(92);

        const maxIdleTime = new Ref<number>(device.Config?.maxIdleTime || 0);
        const minPictureSize = new Ref<number>(device.Config?.minPictureSize || 0);
        const delayBetweenPictures = new Ref<number>(device.Config?.delayBetweenPictures || 0);

        this.content.Padding(16)
            .Append(
                Object.keys(device.Config).length ? new JsonViewer(device.Config).Margin(8).MarginBottom(32)
                    : new Span("No config found. Device not connected?").Margin(8),
                new Line(),
                new LabeledNumberInput("Max idle time", maxIdleTime),
                new LabeledNumberInput("Min picture size", minPictureSize),
                new LabeledNumberInput("Delay between pictures", delayBetweenPictures),
            );

        this.footer.Padding(16).Append(
            new Button("Set").Class("uk-button uk-button-primary")
                .OnClick(() => device.Set(maxIdleTime.value, minPictureSize.value, delayBetweenPictures.value))
        )

        this.OpenWindow();
    }
}
