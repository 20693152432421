"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Radio = exports.LabeledRadioInput = exports.RadioInput = void 0;
var Ref_1 = require("../../Core/Ref");
var Div_1 = require("../Containers/Div");
var ComponentBase_1 = require("../../Core/ComponentBase");
var RefComponentBase_1 = require("../../Core/RefComponentBase");
var RadioInput = /** @class */ (function (_super) {
    __extends(RadioInput, _super);
    function RadioInput(ref, value, uniqueId) {
        var _this = _super.call(this, "input", ref) || this;
        _this.ref = ref;
        _this.value = value;
        _this.uniqueId = uniqueId;
        _this.Attribute("type", "radio");
        _this.Attribute("name", uniqueId);
        _this.Attribute("value", value.toString());
        _this.Refresh();
        return _this;
    }
    RadioInput.prototype.Refresh = function () {
        this.element.checked = this.ref.value == this.value;
    };
    return RadioInput;
}(RefComponentBase_1.RefComponentBase));
exports.RadioInput = RadioInput;
var LabeledRadioInput = /** @class */ (function (_super) {
    __extends(LabeledRadioInput, _super);
    function LabeledRadioInput(ref, value, label, uniqueId) {
        var _this = _super.call(this, "label") || this;
        _this.ref = ref;
        _this.Append(new RadioInput(ref, value, uniqueId), label);
        _this.OnClick(function () { return _this.ref.value = value; });
        return _this;
    }
    return LabeledRadioInput;
}(ComponentBase_1.ComponentBase));
exports.LabeledRadioInput = LabeledRadioInput;
var Radio = /** @class */ (function (_super) {
    __extends(Radio, _super);
    function Radio(ref) {
        if (ref === void 0) { ref = new Ref_1.Ref(0); }
        var _this = _super.call(this) || this;
        _this.ref = ref;
        _this.uniqueName = "radio-id-" + Math.random().toString().substring(2);
        return _this;
    }
    Radio.prototype.Add = function (value, label) {
        this.Append(new LabeledRadioInput(this.ref, value, label, this.uniqueName));
        return this;
    };
    Radio.Name = "Radio";
    return Radio;
}(Div_1.Div));
exports.Radio = Radio;
