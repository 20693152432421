"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.JsonViewer = exports.RefJsonViewer = void 0;
var TwoFloatingColumns_1 = require("../../Containers/TwoFloatingColumns");
var Link_1 = require("../Link");
var Div_1 = require("../../Containers/Div");
var JsonViewerConfig_1 = require("./JsonViewerConfig");
var Span_1 = require("../../Containers/Span");
var CopyIcon_1 = require("./CopyIcon");
var RefJsonViewer = /** @class */ (function (_super) {
    __extends(RefJsonViewer, _super);
    function RefJsonViewer(objAsString, config) {
        var _this = _super.call(this) || this;
        objAsString.OnChange(function (x) {
            var ob;
            try {
                ob = JSON.parse(x);
            }
            catch (ex) {
                return;
            }
            _this.Clear()
                .Append(new JsonViewer(ob, undefined, config));
        }, true);
        return _this;
    }
    return RefJsonViewer;
}(Div_1.Div));
exports.RefJsonViewer = RefJsonViewer;
var ValuePrinter = /** @class */ (function (_super) {
    __extends(ValuePrinter, _super);
    function ValuePrinter(value, style, config) {
        var _this = _super.call(this, value) || this;
        _this.config = config;
        _this.Margin(8).MarginLeft(0).Style(style)
            .CursorPointer()
            .OnClick(function (self) { return _this.Mark(self); });
        return _this;
    }
    ValuePrinter.prototype.Mark = function (element) {
        element.ToggleStyle("background-color", this.config.markedColor, "transparent");
    };
    return ValuePrinter;
}(Span_1.Span));
var ValueTypePrinter = /** @class */ (function (_super) {
    __extends(ValueTypePrinter, _super);
    function ValueTypePrinter(type, config) {
        var _this = _super.call(this, config.typePrinter(type)) || this;
        _this.config = config;
        _this.CursorPointer()
            .Style(config.typeInfoStyle)
            .OnClick(function (self) { return _this.Mark(self); });
        return _this;
    }
    ValueTypePrinter.prototype.Mark = function (element) {
        element.ToggleStyle("background-color", this.config.markedColor, "transparent");
    };
    return ValueTypePrinter;
}(Span_1.Span));
var JsonViewer = /** @class */ (function (_super) {
    __extends(JsonViewer, _super);
    function JsonViewer(obj, key, config) {
        if (key === void 0) { key = ""; }
        var _this = _super.call(this) || this;
        _this.config = config;
        _this.Config = new JsonViewerConfig_1.JsonViewerConfig();
        _this.Config = __assign(__assign({}, _this.Config), _this.config);
        var tk = new TwoFloatingColumns_1.TwoFloatingColumns();
        var objType = typeof obj;
        if (key) {
            tk.LeftColumn.Append(new Span_1.Span(_this.Config.keyPrinter(key, objType)).FloatLeft().MarginRight(6).Style(_this.Config.keyStyle).CursorPointer()
                .OnClick(function (_) { return _.ToggleStyle("background-color", _this.Config.markedColor, "transparent"); }));
        }
        if (obj === null) {
            tk.RightColumn.Append(new ValuePrinter("null", _this.Config.nullStyle, _this.Config), new ValueTypePrinter("null", _this.Config));
        }
        else if (objType === "string") {
            var str = void 0;
            if (obj.length > _this.Config.maxStringLength)
                str = "\"".concat(obj.slice(0, _this.Config.maxStringLength), ".....\"");
            else
                str = "\"".concat(obj, "\"");
            var isLink = obj.startsWith("http");
            var printer = new ValuePrinter(str, _this.Config.stringStyle, _this.Config);
            // if (isLink)
            // {
            //     printer.OnHover(_=>_.Style("text-decoration: underline"))
            //         .OnMouseOut(_=>_.Style("text-decoration: none"))
            // }
            tk.RightColumn.Append(printer);
            tk.RightColumn.Append(new ValueTypePrinter("".concat(obj.length, " char").concat((obj.length > 1 ? "s" : ""), " string"), _this.Config), _this.Config.showCopyIcon && new CopyIcon_1.CopyIcon(obj, _this.Config.copyValueIcon), isLink && new Link_1.Link(_this.Config.openLinkButton, obj).MarginLeft(4).NoDecorationOnHover().OpenInNewCard());
        }
        else if (objType === "number") {
            tk.RightColumn.Append(new ValuePrinter(obj.toString(), _this.Config.numberStyle, _this.Config), new ValueTypePrinter('number', _this.Config), _this.Config.showCopyIcon && new CopyIcon_1.CopyIcon(obj.toString(), _this.Config.copyValueIcon));
        }
        else if (objType === "boolean") {
            tk.RightColumn.Append(new ValuePrinter(obj ? "true" : "false", _this.Config.boolStyle, _this.Config), new ValueTypePrinter('boolean', _this.Config));
        }
        else if (Array.isArray(obj)) {
            if (obj.length === 0) {
                tk.RightColumn.Append(new ValuePrinter("[ ]", "", _this.Config), new ValueTypePrinter("empty array", _this.Config));
            }
            else {
                var foldButton_1 = new Link_1.Link(_this.Config.foldSymbol).NoDecorationOnHover().MarginLeft(4).MarginRight(4).CursorPointer()
                    .OnClick(function () {
                    foldButton_1.Text(tk.RightColumn.IsVisible() ? _this.Config.foldSymbol : _this.Config.unfoldSymbol);
                    tk.RightColumn.Toggle();
                });
                tk.LeftColumn
                    .Append(new ValueTypePrinter("array of ".concat(obj.length, " ").concat(_this.GetType(obj[0])).concat((obj.length > 1 ? "s" : "")), _this.Config), foldButton_1);
                if (_this.Config.foldAll) {
                    tk.RightColumn.Hide();
                    foldButton_1.Text(_this.Config.foldSymbol);
                }
                else {
                    tk.RightColumn.Show();
                    foldButton_1.Text(_this.Config.unfoldSymbol);
                }
                tk.LeftColumn.WidthPercent(100);
                tk.RightColumn.MarginLeft(_this.Config.indent);
                obj.forEach(function (e, i) {
                    return tk.RightColumn.Append(new JsonViewer(e, _this.Config.arrayKeyPrinter(i.toString(), typeof e), _this.config));
                });
            }
        }
        else if (objType === "object") {
            var keys = Object.keys(obj);
            if (keys.length === 0) {
                tk.RightColumn.Append(new ValuePrinter("{ }", "", _this.Config), new ValueTypePrinter("empty object", _this.Config));
            }
            else {
                if (key) {
                    var foldButton_2 = new Link_1.Link(_this.Config.foldSymbol).NoDecorationOnHover().MarginLeft(4).MarginRight(4).CursorPointer()
                        .OnClick(function () {
                        foldButton_2.Text(tk.RightColumn.IsVisible() ? _this.Config.foldSymbol : _this.Config.unfoldSymbol);
                        tk.RightColumn.Toggle();
                    });
                    tk.LeftColumn.Append(new ValueTypePrinter("".concat(keys.length, " field").concat((keys.length > 1 ? "s" : ""), " object"), _this.Config), foldButton_2);
                    tk.LeftColumn.WidthPercent(100);
                    tk.RightColumn.MarginLeft(_this.Config.indent);
                    if (_this.Config.foldAll) {
                        tk.RightColumn.Hide();
                        foldButton_2.Text(_this.Config.foldSymbol);
                    }
                    else {
                        tk.RightColumn.Show();
                        foldButton_2.Text(_this.Config.unfoldSymbol);
                    }
                }
                keys.forEach(function (k) {
                    var v = obj[k];
                    tk.RightColumn.Append(new JsonViewer(v, k, _this.config));
                });
            }
        }
        _this.Append(tk);
        _this.Append(new Div_1.Div().ClearBoth());
        return _this;
    }
    JsonViewer.prototype.GetType = function (obj) {
        var objType = typeof obj;
        var isArray = Array.isArray(obj);
        if (isArray && obj.length > 0)
            return "array of ".concat(this.GetType(obj[0]), "s");
        else if (isArray)
            return "array";
        else
            return objType;
    };
    JsonViewer.Name = "JsonViewer";
    return JsonViewer;
}(Div_1.Div));
exports.JsonViewer = JsonViewer;
