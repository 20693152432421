import { RawDeviceProfile } from "../Models/DeviceProfile";
import io from 'socket.io-client';
import axios from "axios";
import { HandlersCollection } from "../Utils/HandlersCollection";
import { LoginResult } from "../Models/LoginResult";
import { ConfigProvider } from "./ConfigProvider";
import { Filter } from "../Models/Filter";
import { HeartbeatPayload } from "../Components/HeartbeatPayload";


export class Server
{
    public get Id(): string
    {
        return this._config.CurrentServerId;
    }
    public socket;
    private authToken: string = window.localStorage.getItem("auth-token") || "";
    private onStatusChange = new HandlersCollection<(text: string) => void>();
    private onDevicesUpdate = new HandlersCollection<(devices: RawDeviceProfile[]) => void>();
    private onNewDevice = new HandlersCollection<(device: RawDeviceProfile) => void>();
    private onDeviceLost = new HandlersCollection<(tid: string) => void>();
    private onPictureUpdate = new HandlersCollection<(tid: string, pic: string, index: number, time: Date) => void>();
    private onHeartbeat = new HandlersCollection<(payload: HeartbeatPayload) => void>();
    private onProblem = new HandlersCollection<(problem: string) => void>();

    constructor(private _config: ConfigProvider)
    { }

    public async GetPasswords()
    {
        return await this.Query("passwords")
    }

    public async GetStorageUrl(): Promise<string>
    {
        return await this.Query<string>("storage")
    }

    public async SetPasswordsInServerMemory(passwords): Promise<boolean>
    {
        return await this.CommandV2("load-passwords-to-memory", passwords)
    }

    public get ConnectedTo(): string
    {
        return this._config.CurrentServerName;
    }

    public Refresh(filter: Filter): void
    {
        this.currentFilter = filter;

        this.Log(`Requesting ${filter} devices...`);

        this.socket.emit("get-devices", filter);
    }

    public Logout(): void
    {
        this.authToken = "";
        window.localStorage.setItem("auth-token", "")
        this.socket?.disconnect();
    }

    public get IsLoggedIn()
    {
        return !!this.authToken;
    }

    public async TryLogin(password: string): Promise<LoginResult>
    {
        try 
        {
            console.log(`Trying to login to ${this._config.CurrentServerAddr.value} with`, password);

            const response = await axios.get(`${this._config.CurrentServerAddr.value}/try-login-admin/` + password);
            const result = response.data;
            console.log('Result:', result);

            if (response.status == 200 && result.message === "OK")
            {
                window.localStorage.setItem("auth-token", result.token)
                this.authToken = result.token;

                console.log('Authorized', result.token);

                return new LoginResult(true);
            }
            else 
            {
                console.log('Non-200 login result:', response);
                return new LoginResult(false, "Invalid password");
            }
        }
        catch (ex)
        {
            console.log('Faulty login result:', ex.message);
            return new LoginResult(false, "Network problem");
        }
    }

    public async Command(url: string): Promise<boolean>
    {
        try 
        {
            const headers = {
                Authorization: this.authToken,
            };

            const response = await axios.get(`${this._config.CurrentServerAddr.value}/${url}`, { headers });

            return response.status >= 200 && response.status < 300;
        }
        catch (ex)
        {
            this.Log(`Command execution problem: ${ex.message}`);

            return false;
        }
    }

    public async CommandV2(url: string, payload?): Promise<boolean>
    {
        try 
        {
            const headers = {
                Authorization: this.authToken,
                'Content-Type': 'application/json',
            };

            const response = await axios.post(`${this._config.CurrentServerAddr.value}/${url}`, payload, { headers });

            return response.status >= 200 && response.status < 300;
        }
        catch (ex)
        {
            this.Log(`Command execution problem: ${ex.message}`);

            return false;
        }
    }

    public async Query<T>(url: string): Promise<T>
    {
        try 
        {
            const headers = {
                Authorization: this.authToken
            };

            const response = await axios.get(`${this._config.CurrentServerAddr.value}/${url}`, { headers });

            return response.data as T;
        }
        catch (ex)
        {
            this.Log(`Query problem: ${ex.message}`);
            throw ex;
        }
    }

    public OnStatusChange(handler: (text: string) => void): this
    {
        this.onStatusChange.Add(handler);
        return this;
    }

    public OnHeartbeat(handler: (payload: HeartbeatPayload) => void): this
    {
        this.onHeartbeat.Add(handler);
        return this;
    }

    public OnPictureUpdate(handler: (tid: string, pic: string, index: number, time: Date) => void): this
    {
        this.onPictureUpdate.Add(handler);
        return this;
    }

    public OnDevicesUpdate(handler: (devices: RawDeviceProfile[]) => void): this
    {
        this.onDevicesUpdate.Add(handler);
        return this;
    }

    public OnNewDevice(handler: (device: RawDeviceProfile) => void): this
    {
        this.onNewDevice.Add(handler);
        return this;
    }

    // OnDeviceUpdate(handler: (status: DeviceConnectionStatus) => void)
    // {
    //     return this;
    // }
    public OnDeviceLost(handler: (tid: string) => void): this
    {
        this.onDeviceLost.Add(handler);
        return this;
    }

    public OnProblem(handler: (problem: string) => void): this
    {
        this.onProblem.Add(handler);
        return this;
    }

    public get IsConnected()
    {
        return !!this.socket?.connected && this.heartBeats;
    }

    private heartBeats = false;
    private heartTimer;

    private currentFilter;

    public Connect(filter: Filter): this
    {
        this.currentFilter = filter;
        this.Log(`Connecting to ${this._config.CurrentServerAddr.value} with auth token "${this.authToken}"...`);

        this.socket = io(this._config.CurrentServerAddr.value, {
            query: {
                who: "admin",
                auth: this.authToken
            }
        });

        this.socket
            .on('connect', () => 
            {
                this.Log(`Connected. Requesting ${filter} devices...`);

                this.Refresh(filter)
            })
            .on('disconnect', () =>
            {
                this.Log(`Socket disconnected.`);
                this.onProblem.Call("disconnected");
            })
            .on('heartbeat', (cnt, date) =>
            {
                this.onHeartbeat.Call(cnt, date)
                clearTimeout(this.heartTimer);
                this.heartBeats = true;
                this.heartTimer = setTimeout(() => this.heartBeats = false, 2000)
            })
            .on('devices', (profiles: RawDeviceProfile[]) =>
            {
                this.Log(`Found ${profiles.length} ${(this.currentFilter != Filter.All ? this.currentFilter : "")} device${(profiles.length != 1 ? "s" : "")}`);
                // this.Log(profiles.map(x => x.Id + ":" + x.ConnectionStatus).join())
                this.onDevicesUpdate.Call(profiles);
            })
            .on('new-device', (profile: RawDeviceProfile) =>
            {
                this.Log(`${profile.Id} just connected`);
                this.onNewDevice.Call(profile);
            })
            .on('device-lost', (tid: string) =>
            {
                this.Log(`${tid} just disconnected`);
                this.onDeviceLost.Call(tid);
            })
            .on('unauthorized', () =>
            {
                // alert("Unauthorized!!!")
                this.Log(`Message from server: Unauthorized`);
                this.onProblem.Call("unauthorized");
            })
            .on('last-picture', (tid, lastPic, indexFromDevice, time) =>
            {
                this.onPictureUpdate.Call(tid, lastPic, indexFromDevice, new Date(time))
            })
            .onAny((event: string, senderId: string, ...eventArgs: any[]) =>
            {
                // if (event != "is-alive")
                // {
                //     console.log('________________');
                //     console.log('event:', event);
                //     console.log('sender:', senderId);
                //     console.log('args:', ...eventArgs);
                // }

                this.onAnyEventHandler.Call(senderId, event, eventArgs)
            })

        return this;
    }

    public OnEvent(handler: (tid: any, event: any, args: any) => void)
    {
        this.onAnyEventHandler.Add(handler);
    }

    // private onShellResultHandler = new HandlersCollection<(tid: string, result: string) => void>();
    private onAnyEventHandler = new HandlersCollection<(tid: string, event: string, ...args: any[]) => void>();

    // public OnShellResult(handler: (tid: string, result: string) => void): this
    // {
    //     this.onShellResultHandler.Add(handler);

    //     return this;
    // }

    private Log(text)
    {
        console.log(text)
        this.onStatusChange.Call(text);
    }
}
