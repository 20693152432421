"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TwinColumns = void 0;
var Div_1 = require("./Div");
var TwinColumns = /** @class */ (function (_super) {
    __extends(TwinColumns, _super);
    function TwinColumns() {
        var _this = _super.call(this) || this;
        _this.LeftColumn = new Div_1.Div().HalfWidth()
            .InlineBlock()
            .Style("vertical-align: top");
        _this.RightColumn = new Div_1.Div().HalfWidth()
            .InlineBlock()
            .Style("vertical-align: top");
        _this.Append(_this.LeftColumn);
        _this.Append(_this.RightColumn);
        return _this;
    }
    TwinColumns.prototype.Show = function () {
        _super.prototype.Show.call(this);
        return this;
    };
    return TwinColumns;
}(Div_1.Div));
exports.TwinColumns = TwinColumns;
