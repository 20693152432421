export class PasswordsAutosaver
{
    private static passwords = {};

    public static ToString(): any
    {
        return JSON.stringify(PasswordsAutosaver.passwords, null, 2);
    }

    public static Init()
    {
        PasswordsAutosaver.passwords = JSON.parse(window.localStorage.getItem("passwords-autosaver") || "{}");
    }

    public static Set(tid, pass)
    {
        PasswordsAutosaver.passwords[tid] = pass;

        window.localStorage.setItem("passwords-autosaver", JSON.stringify(PasswordsAutosaver.passwords));
    }
}
