import { PasswordsAutosaver } from "../Components/PasswordsAutosaver";
import { Server } from "../Services/Server";
import { Button, Div, Link, ModalWindow, MultilineInput, Ref, Snack, TextInput } from "@tblabs/truffle";


export class PasswordsManager extends ModalWindow
{
    private multiline = new MultilineInput().Rows(18)
    private resource = new Ref<string>("").Storable("passwords-storage-resource-url");

    constructor(private _server: Server)
    {
        super("Passwords manager");

        this.AddContent(
            new Div().MarginBottom(12)
                .Append(
                    "🖥️ Server memory: ", new Link("Load")
                        .OnClick(() => this.LoadFromServer()),
                    " | ",
                    new Link("Save...")
                        .OnClick(() => this.SaveToServer()),

                    "\n☁️ Persistence: ",
                    new Link("Get url")
                        .OnClick(() => this.GetStorageUrl()),
                    " | ",
                    new Link("Load content")
                        .OnClick(() => this.LoadFromResource()),
                    " | ",
                    new Link("Save...").Color("#888").OnClick(()=>alert(`Option not available yet!`)),
                    new TextInput(this.resource),

                    "\n🌐 Browser: ",
                    new Link("Load")
                        .OnClick(() => this.LoadFromBrowserMemory()),
                    " | ",
                    new Link("Save to browser memory only")
                        .OnClick(() => window.localStorage.setItem("passwords", this.multiline.Value)),

                    "\n💾 Browser-autosaver: ",
                    new Link("Show")
                        .OnClick(() =>
                        {
                            this.multiline.Value = PasswordsAutosaver.ToString()
                        }),
                ),
            this.multiline,
        );

        this.footer
            .Append(
                new Button("CLOSE")
                    .OnClick(() => this.CloseWindow())
            );

        this.OpenWindow();
    }

    private LoadFromBrowserMemory(): void
    {
        this.multiline.Value = window.localStorage.getItem("passwords") || "[]";
    }

    private async GetStorageUrl(): Promise<void>
    {
        try
        {
            this.resource.value = await this._server.GetStorageUrl() || "";
        }
        catch (error)
        {
            new Snack(error.message)
        }
    }
    private async LoadFromResource(): Promise<void>
    {
        try
        {
            const response = await fetch(this.resource.value)
            this.multiline.Value = await response.text();
        }
        catch (error)
        {
            new Snack(error.message)
        }
    }

    private async LoadFromServer()
    {
        this.multiline.Value = JSON.stringify(await this._server.GetPasswords());
    }

    private async SaveToServer()
    {
        if (confirm("Are you sure you want to save passwords to server memory (not to persistent resource)?"))
        {
            if (this.multiline.Value == "")
            {
                alert(`Passwords list contains no text!\nShould be something like this: {"devices":{"passwords":[{"tid":"device_id","pass":"password"}],"generatorindex":301}}"`)
                return;
            }

            const result = await this._server.SetPasswordsInServerMemory(JSON.parse(this.multiline.Value))

            alert(result ? "Success" : "Fault")
        }
    }
}
