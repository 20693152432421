"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabeledNumberInput = void 0;
var Div_1 = require("../Containers/Div");
var NumberInput_1 = require("./NumberInput");
var TextLabel_1 = require("./TextLabel");
var LabeledNumberInput = /** @class */ (function (_super) {
    __extends(LabeledNumberInput, _super);
    function LabeledNumberInput(label, ref) {
        var _this = _super.call(this, "tf-form-labeled-number") || this;
        _this.label = label;
        _this.ref = ref;
        _this.labelCmp = new TextLabel_1.TextLabel(_this.label).Class("label");
        _this.numberInput = new NumberInput_1.NumberInput(_this.ref).Class("input");
        _this.Append(_this.labelCmp, _this.numberInput);
        return _this;
    }
    LabeledNumberInput.prototype.Min = function (min) {
        this.numberInput.Min(min);
        return this;
    };
    LabeledNumberInput.prototype.Max = function (max) {
        this.numberInput.Max(max);
        return this;
    };
    LabeledNumberInput.prototype.Step = function (step) {
        this.numberInput.Step(step);
        return this;
    };
    LabeledNumberInput.prototype.Edit = function (hook) {
        hook(this.labelCmp, this.numberInput);
        return this;
    };
    return LabeledNumberInput;
}(Div_1.Div));
exports.LabeledNumberInput = LabeledNumberInput;
