import { DeviceConfig } from "../Models/DeviceConfig";
import { DeviceProfile } from "../Models/DeviceProfile";
import { HandlersCollection } from "../Utils/HandlersCollection";
import { CustomerAccessMessageBuilder } from "./CustomerAccessMessageBuilder";
import { Server } from "./Server";
import { CopyToClipboard } from "../Utils/CopyToClipboard";
import moment from "moment";
import { RegexExtractor } from "@tblabs/truffle";
import { DeviceConnectionStatus } from "../Models/DeviceConnectionStatus";
import { HotspotType } from "./HotspotType";
import { HotspotInfo } from "./HotspotInfo";
import { PasswordsAutosaver } from "../Components/PasswordsAutosaver";


export class Device
{
    private onShellResultHandler = new HandlersCollection<(result: string) => void>();
    private onFileLoadHandler = new HandlersCollection<(dir: string, fileContent: string) => void>();
    private onFileSavedHandler = new HandlersCollection<(dir: string) => void>();
    private onFileProblemHandler = new HandlersCollection<(problem: string) => void>();
    private onLedStatusChangeHandler = new HandlersCollection<(status: string) => void>();
    public get Server(): Server
    {
        return this._server;
    }

    public get Config(): DeviceConfig
    {
        return this.profile.Config;
    }

    public get IsAtHome()
    {
        return this.Hotspot.Type == HotspotType.Home
            && this.profile.ConnectionStatus == DeviceConnectionStatus.Connected
    }

    public OnShellResult(handler: (result: string) => void): this
    {
        this.onShellResultHandler.Add(handler);
        return this;
    }
    public OnFileContentLoad(handler: (fileContent: string) => void): this
    {
        this.onFileLoadHandler.Add(handler);
        return this;
    }
    public OnFileSaved(handler: (dir: string) => void): this
    {
        this.onFileSavedHandler.Add(handler);
        return this;
    }
    public OnFileProblem(handler: (problem: string) => void): this
    {
        this.onFileProblemHandler.Add(handler);
        return this;
    }
    public OnLedStatusChange(handler: (status: string) => void): this
    {
        this.onLedStatusChangeHandler.Add(handler);
        return this;
    }

    constructor(public profile: DeviceProfile, private _server: Server)
    {
        this._server.OnEvent((tid, event, args) =>
        {
            if (tid != profile.Id)
            {
                return;
            }

            switch (event)
            {
                case "shell-result":
                    const result = args[0];
                    this.onShellResultHandler.Call(result);
                    break;
                case "file":
                    const fileDir = args[1];
                    const fileContent = args[0];
                    this.onFileLoadHandler.Call(fileDir, fileContent);
                    break;
                case "file-saved":
                    const dir = args[0];
                    this.onFileSavedHandler.Call(dir);
                    break;
                case "file-problem":
                    const filePro = args[0];
                    this.onFileProblemHandler.Call(filePro);
                    break;
                case "led-status":
                    const status = args[0];
                    this.onLedStatusChangeHandler.Call(status);
                    break;
            }
        })
    }

    public get IsHalted(): boolean
    {
        return this.profile.Config.halt;
    }

    public get IsLocked(): boolean
    {
        return this.profile.IsLocked;
    }

    public get PictureQuality(): string
    {
        if (!this.profile?.PictureConfig?.width)
        {
            return "";
        }

        return `${this.profile?.PictureConfig?.width || "?"} × ${this.profile?.PictureConfig?.height || "?"}`;
    }

    public get LastPictureTime(): string
    {
        if (+this.profile.LastPictureTime == 0)
        {
            return "no pic";
        }
        let date = moment(this.profile.LastPictureTime);
        return date.fromNow();
    }

    public get Hotspot(): HotspotInfo
    {
        const regexExtractor = new RegexExtractor(/ESSID:"([\w\d]+)"/gm);
        let wifi = regexExtractor.Extract(this.Config.wifi);
        let type = HotspotType.Unknown;

        if (wifi == undefined)
            type = HotspotType.Unknown;
        else if (wifi == "hotspot0")
            type = HotspotType.Home;
        else if (wifi == "hotspot1" || wifi == "Hotspot1")
            type = HotspotType.Initial;
        else if (wifi.startsWith("T")) // TX..., TY... etc.
            type = HotspotType.Dedicated;
        else if (["Orange Free Wifi 5G", "Sala wykladowa C14-F", "VP3C4F44Y-MP9"].includes(wifi))
            type = HotspotType.Concealed;
        else
            type = HotspotType.Custom;

        return new HotspotInfo(wifi, type);
    }

    public get LastPictureSize(): string
    {
        const size = this.profile.Picture.value ? (this.profile.Picture.value.length / 1024).toFixed(0) + "kb" : "";
        return size;
    }

    public GetLastPicture(): void
    {
        this.Emit('get-last-picture')
    }

    public LedOn()
    {
        this.Emit('cmd', 'led-engine', 'on');
    }
    public LedOff()
    {
        this.Emit('cmd', 'led-engine', 'off');
    }

    public async GenerateSMSAndCopyToClipboard(): Promise<void>
    {
        if (this.IsLocked) throw new Error("Device is locked. Regenerate password first.");

        const sms = CustomerAccessMessageBuilder.Sms(this.profile.Password.value, this.profile.Id, this._server.Id);

        await CopyToClipboard(sms);
    }
    public async GenerateLetterAndCopyToClipboard(): Promise<void>
    {
        if (this.IsLocked) throw new Error("Device is locked. Regenerate password first.");

        const letter = CustomerAccessMessageBuilder.Letter(this.profile.Password.value, this.profile.Id, this._server.Id);

        await CopyToClipboard(letter);
    }
    public async RegeneratePasswordAndCopySMSMessage(): Promise<void>
    {
        await this.RegeneratePassword();
        await this.GenerateSMSAndCopyToClipboard();
    }
    public Kill()
    {
        // await this._server.Command(`cmd/${this.profile.Id}/die`); // TODO: czy to działa?/
        this.Emit('cmd', 'die'); // TODO: czy to działa?/
    }

    public Reboot()
    {
        this.Emit('shell-cmd', 'sudo reboot');
    }
    private Emit(event, ...params)
    {
        console.log(`Emiting "${event}" event to "${this.profile.Id}" with params "${params.join()}"...`)
        this._server.socket.emit(event, this.profile.Id, ...params);
    }
    public SendShellCommand(cmd)
    {
        this.Emit('shell-cmd', cmd);
    }
    public LoadFile(dir)
    {
        this.Emit('download-file', dir);
    }
    public SaveFile(dir, content)
    {
        this.Emit('upload-file', dir, content);
    }

    public Set(maxIdleTime, minPictureSize, delayBetweenPictures)
    {
        this.Emit('set-settings', { maxIdleTime, minPictureSize, delayBetweenPictures });
    }

    public async RegeneratePassword(): Promise<void>
    {
        this.profile.Password.value = await this._server.Query<string>(`regenerate-password/${this.profile.Id}`);

        PasswordsAutosaver.Set(this.profile.Id, this.profile.Password.value)
    }

    public async ResetPassword(): Promise<void>
    {
        if (this.IsLocked)
            throw new Error("Already locked");

        this.profile.Password.value = await this._server.Query<string>(`reset-password/${this.profile.Id}`);

        PasswordsAutosaver.Set(this.profile.Id, this.profile.Password.value)
    }

    public async SetPassword(password: string): Promise<boolean>
    {
        const result: boolean = await this._server.Command(`set-password/${this.profile.Id}/${password}`);

        PasswordsAutosaver.Set(this.profile.Id, password)

        return result;
    }
}
