"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditableDateLabel = void 0;
var Label_1 = require("./Label");
var TextLabel_1 = require("./TextLabel");
var DateInput_1 = require("./DateInput");
var Div_1 = require("../Containers/Div");
var EditableDateLabel = /** @class */ (function (_super) {
    __extends(EditableDateLabel, _super);
    function EditableDateLabel(ref) {
        var _this = _super.call(this) || this;
        var dayAndMonth = function (value) { return value.getDate() + "." + (value.getMonth() + 1); };
        _this.Label = new Label_1.Label(ref, dayAndMonth)
            .CursorPointer()
            .OnClick(function () {
            _this.Label.Hide();
            _this.DateInput.Show();
            _this.OffButton.Show();
        });
        _this.DateInput = new DateInput_1.DateInput(ref).Inline()
            .Hide()
            .OnChange(function (date) {
            var _a;
            _this.DateInput.Hide();
            _this.OffButton.Hide();
            _this.Label.Show();
            (_a = _this.onChange) === null || _a === void 0 ? void 0 : _a.call(_this, date);
        })
            .OnBlur(function () {
            _this.DateInput.Hide();
            _this.OffButton.Hide();
            _this.Label.Show();
        });
        _this.OffButton = new TextLabel_1.TextLabel("✖").CursorPointer().Inline()
            .Hide();
        _this.OffButton.OnClick(function () {
            _this.Label.Show();
            _this.DateInput.Hide();
            _this.OffButton.Hide();
        });
        _this.DateInput.Width(85, "%");
        _this.OffButton.MarginLeft(6);
        // NIE DA SIE KLIKNĄĆ W KONTROLKE BO ŁAPIE BLURA :(
        // this.SetAttribute("tabindex", "0");
        // this.OnBlur(() =>
        // {
        //     console.log('blsssssssur');
        //     this.DateInput.Hide();
        //     this.Label.Show();
        // });
        _this.Append(_this.Label, _this.DateInput, _this.OffButton);
        return _this;
    }
    EditableDateLabel.prototype.OnChange = function (callback) {
        this.onChange = callback;
        return this;
    };
    EditableDateLabel.prototype.DateInputConfig = function (config) {
        config(this.DateInput);
        return this;
    };
    EditableDateLabel.prototype.OffButtonConfig = function (config) {
        config(this.OffButton);
        return this;
    };
    EditableDateLabel.prototype.LabelConfig = function (config) {
        config(this.Label);
        return this;
    };
    EditableDateLabel.Name = "EditableDateLabel";
    return EditableDateLabel;
}(Div_1.Div));
exports.EditableDateLabel = EditableDateLabel;
