"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrayTemplate = void 0;
var ArrayTemplate = /** @class */ (function () {
    function ArrayTemplate(CreateFn) {
        this.CreateFn = CreateFn;
    }
    return ArrayTemplate;
}());
exports.ArrayTemplate = ArrayTemplate;
