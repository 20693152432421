export class HandlersCollection<T extends (...args) => void> {
    private handlers: T[] = [];

    public Reset()
    {
        this.handlers = [];
    }

    public Add(handler: T): void
    {
        if (handler)
            this.handlers.push(handler);
    }

    public get AreAny(): boolean
    {
        return this.handlers.length > 0;
    }

    public Call(...params: any[]): void
    {
        this.handlers.forEach(h => h(...params));
    }
}
