"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DestroyingContentSwitcher = void 0;
var RefComponentBase_1 = require("../../../Core/RefComponentBase");
var DestroyingContentSwitcher = /** @class */ (function (_super) {
    __extends(DestroyingContentSwitcher, _super);
    function DestroyingContentSwitcher(ref) {
        var _this = _super.call(this, "div", ref) || this;
        _this.contents = {};
        return _this;
    }
    DestroyingContentSwitcher.prototype.AddContent = function (activator, cmp) {
        this.contents[activator.toString()] = cmp;
        if (activator === this.ref.value) {
            this.Append(cmp());
        }
        else {
            if (this.defaultContent)
                this.Append(this.defaultContent());
        }
        return this;
    };
    DestroyingContentSwitcher.prototype.AddDefaultContent = function (cmp) {
        this.defaultContent = cmp;
        if (!this.contents.Keys().includes(this.ref.value.toString())) {
            this.Append(cmp());
        }
        return this;
    };
    DestroyingContentSwitcher.prototype.Refresh = function () {
        var _a, _b;
        var c = (_b = (_a = this.contents)[this.ref.value.toString()]) === null || _b === void 0 ? void 0 : _b.call(_a);
        this.Clear();
        if (c) {
            this.Append(c);
        }
        else {
            if (this.defaultContent)
                this.Append(this.defaultContent());
        }
    };
    DestroyingContentSwitcher.Name = "DestroyingContentSwitcher";
    return DestroyingContentSwitcher;
}(RefComponentBase_1.RefComponentBase));
exports.DestroyingContentSwitcher = DestroyingContentSwitcher;
