"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RefDate = void 0;
var Ref_1 = require("./Ref");
var RefDate = /** @class */ (function (_super) {
    __extends(RefDate, _super);
    function RefDate(initialDate) {
        if (initialDate === void 0) { initialDate = new Date(0); }
        var _this = this;
        if (initialDate instanceof Date) {
            _this = _super.call(this, initialDate, false) || this;
        }
        else if (typeof initialDate === "number") {
            _this = _super.call(this, new Date(initialDate), false) || this;
        }
        else if (typeof initialDate === "string") {
            _this = _super.call(this, new Date(initialDate), false) || this;
        }
        return _this;
    }
    Object.defineProperty(RefDate.prototype, "IsToday", {
        get: function () {
            var now = new Date();
            return this.value.getFullYear() === now.getFullYear() &&
                this.value.getMonth() === now.getMonth() &&
                this.value.getDate() === now.getDate();
        },
        enumerable: false,
        configurable: true
    });
    return RefDate;
}(Ref_1.Ref));
exports.RefDate = RefDate;
