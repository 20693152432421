"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableHeaderV2 = void 0;
var __1 = require("../..");
var TableHeaderV2 = /** @class */ (function (_super) {
    __extends(TableHeaderV2, _super);
    function TableHeaderV2(columnsHeaders) {
        var _this = _super.call(this, "thead") || this;
        var maxRows = Math.max.apply(Math, columnsHeaders.map(function (hs) { return hs.length; }));
        var _loop_1 = function (rowIndex) {
            var cells = columnsHeaders.map(function (x) { return x[rowIndex]; });
            this_1.Append(new __1.TableHeaderRow(cells));
        };
        var this_1 = this;
        for (var rowIndex = 0; rowIndex < maxRows; rowIndex++) {
            _loop_1(rowIndex);
        }
        return _this;
    }
    return TableHeaderV2;
}(__1.ComponentBase));
exports.TableHeaderV2 = TableHeaderV2;
