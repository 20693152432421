"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Snack = void 0;
var _1 = require("../Core/$");
var Div_1 = require("./Containers/Div");
var Span_1 = require("./Containers/Span");
var Snack = /** @class */ (function (_super) {
    __extends(Snack, _super);
    function Snack(header, content, _a) {
        if (content === void 0) { content = ""; }
        var _b = _a === void 0 ? {} : _a, _c = _b.autoCloseTimeout, autoCloseTimeout = _c === void 0 ? 4500 : _c, _d = _b.showCloseButton, showCloseButton = _d === void 0 ? true : _d, _e = _b.clickAnywhereToClose, clickAnywhereToClose = _e === void 0 ? false : _e;
        var _f;
        var _this = _super.call(this) || this;
        _this.Class(Snack.DefaultCssClasses);
        _this.Append(new Div_1.Div().Append(header).Class("tf-snack-label"), new Div_1.Div().Append(content).Class("tf-snack-text"), showCloseButton && new Span_1.Span("x").Class("tf-snack-x")
            .OnClick(function () { return _this.Close(); }));
        if (clickAnywhereToClose) {
            _this.OnClick(function () {
                _this.Close();
            });
        }
        (_f = (0, _1.$)("body")) === null || _f === void 0 ? void 0 : _f.Append(_this.Element);
        if (autoCloseTimeout) {
            setTimeout(function () { return _this.Close(); }, autoCloseTimeout);
        }
        return _this;
    }
    Snack.prototype.Close = function () {
        var _a;
        (_a = (0, _1.$)("body")) === null || _a === void 0 ? void 0 : _a.Remove(this.Element);
        return this;
    };
    Snack.Name = "Snack";
    Snack.DefaultCssClasses = "tf-snack";
    return Snack;
}(Div_1.Div));
exports.Snack = Snack;
