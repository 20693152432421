"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.VerticalButtonTabs = void 0;
var Button_1 = require("../Elements/Button");
var AutoWidthTwoColumns_1 = require("../Containers/AutoWidthTwoColumns");
var DestroyingContentSwitcher_1 = require("./Core/DestroyingContentSwitcher");
var Ref_1 = require("../../Core/Ref");
var RadioSwitchesBuilder_1 = require("./Core/RadioSwitchesBuilder");
var VerticalButtonTabs = /** @class */ (function (_super) {
    __extends(VerticalButtonTabs, _super);
    function VerticalButtonTabs(ref) {
        if (ref === void 0) { ref = new Ref_1.Ref(""); }
        var _this = _super.call(this) || this;
        _this.ref = ref;
        _this.switcher = new RadioSwitchesBuilder_1.RadioSwitchesBuilder(_this.ref);
        _this.contentSwitcher = new DestroyingContentSwitcher_1.DestroyingContentSwitcher(_this.ref);
        _this.useActivator = function (label) { return new Button_1.Button(label); };
        _this.LeftColumn.Append(_this.switcher);
        _this.RightColumn.Append(_this.contentSwitcher);
        return _this;
    }
    VerticalButtonTabs.prototype.Add = function (label, content) {
        this.switcher.AddSwitch(label, this.useActivator(label), "uk-button-danger");
        this.contentSwitcher.AddContent(label, content);
        return this;
    };
    VerticalButtonTabs.prototype.UseActivator = function (fn) {
        this.useActivator = fn;
        return this;
    };
    return VerticalButtonTabs;
}(AutoWidthTwoColumns_1.AutoWidthTwoColumns));
exports.VerticalButtonTabs = VerticalButtonTabs;
