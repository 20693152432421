import { Div } from "@tblabs/truffle";
import { ILogger } from "./ILogger";



export class Logger extends Div implements ILogger
{
    constructor()
    {
        super();
        this.FontSize(12).Color("#222");
    }

    public Log(msg: string): void
    {
        this.Append(msg + "\n");
    }
}
