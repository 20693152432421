"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableColumn = void 0;
var TableColumn = /** @class */ (function () {
    function TableColumn(Headers, Mapping) {
        this.Headers = Headers;
        this.Mapping = Mapping;
    }
    return TableColumn;
}());
exports.TableColumn = TableColumn;
