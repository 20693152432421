import { Button, CollectionView, EditableLabel, Filters, Link, ModalWindow, Ref, TableBuilder, TextInput } from "@tblabs/truffle";
import { AddCommandWindow } from "./AddCommandWindow";
import { Storage } from "./Storage";


export class CommandsListWindow extends ModalWindow
{
    constructor(_storage: Storage)
    {
        super("Commands edit");
        this.WidthPercent(80)

        const filter = new Ref<string>("");

        const commands = _storage.CommandsCollection;
        const view = new CollectionView(commands)
            .Filter(filter, entry => Filters.TextContains(filter, entry.Command.value) || Filters.TextContains(filter, entry.Label.value))
            .Refresh();

        const table = new TableBuilder(view)
            .AddColumn("Label", entry => new EditableLabel(entry.Label).DefaultText("(label)"))
            .AddColumn(["Command", new TextInput(filter)], entry => new EditableLabel(entry.Command).DefaultText("(command)"))
            .AddColumn("Options", entry => new Link("❌").NoDecorationOnHover().Opacity(0.7).OnClick(() => _storage.Remove(entry)))
            .Build();

        this.content
            .Append(table);
        this.footer
            .Append(
                new Button("Add").Class("uk-button uk-button-primary").FloatLeft()
                    .OnClick(() =>
                    {
                        new AddCommandWindow(_storage).OpenWindow();
                    }),
                new Button("Save & Exit").Class("uk-button uk-button-primary")
                    .OnClick(() =>
                    {
                        _storage.CommandsCollection.Modify(_ => commands.Items);
                        _storage.Save();
                        this.CloseWindow();
                    })
            );
    }
}
