"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RefNumber = void 0;
var Ref_1 = require("./Ref");
var RefNumber = /** @class */ (function (_super) {
    __extends(RefNumber, _super);
    function RefNumber(initialDate) {
        if (initialDate === void 0) { initialDate = 0; }
        return _super.call(this, initialDate, false) || this;
    }
    RefNumber.prototype.IsBigger = function (toCompare) {
        return this.Compare(this, toCompare, function (a, b) { return a > b; });
    };
    RefNumber.prototype.IsBiggerOrEqual = function (toCompare) {
        return this.Compare(this, toCompare, function (a, b) { return a >= b; });
    };
    RefNumber.prototype.IsSmaller = function (toCompare) {
        return this.Compare(this, toCompare, function (a, b) { return a < b; });
    };
    RefNumber.prototype.IsSmallerrOrEqual = function (toCompare) {
        return this.Compare(this, toCompare, function (a, b) { return a <= b; });
    };
    RefNumber.prototype.Plus = function (x) {
        return this.Mix(x, function (a, b) { return a + b; });
    };
    RefNumber.prototype.Minus = function (x) {
        return this.Mix(x, function (a, b) { return a - b; });
    };
    RefNumber.prototype.Multiply = function (x) {
        return this.Mix(x, function (a, b) { return a * b; });
    };
    RefNumber.prototype.Divide = function (x) {
        return this.Mix(x, function (a, b) { return a / b; });
    };
    return RefNumber;
}(Ref_1.Ref));
exports.RefNumber = RefNumber;
