import { ModalWindow } from "@tblabs/truffle";
import { Device } from "../Services/Device";
import { DeviceMenuButton } from "../Components/DeviceMenuButton";

export class LedControlWindow extends ModalWindow
{
    constructor(device: Device)
    {
        super(`Led control for ${device.profile.Id}`, true, true, true);
        this.MinWidth(360);

        this.content.TextAlignCenter().Append(
            new DeviceMenuButton("🌞", "Led On")
                .OnClick(() => device.LedOn()),
            new DeviceMenuButton("🌑", "Led Off")
                .OnClick(() => device.LedOff())
        );

        this.OpenWindow();
    }
}
