"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileInput = exports.FileInputV1 = void 0;
var ComponentBase_1 = require("../../Core/ComponentBase");
var HandlersCollection_1 = require("../../Core/Tools/HandlersCollection");
var FileInputV1 = /** @class */ (function (_super) {
    __extends(FileInputV1, _super);
    function FileInputV1() {
        var _this = _super.call(this, "input") || this;
        _this.onChange = new HandlersCollection_1.HandlersCollection();
        _this.Attribute("type", "file");
        _this.Class(FileInput.DefaultCssClasses);
        _this.element.addEventListener('change', function (e) {
            var _a;
            // console.log(this.element.files[0])
            _this.onChange.Call((_a = _this.element.files) === null || _a === void 0 ? void 0 : _a[0], e);
        });
        return _this;
    }
    FileInputV1.prototype.OnChange = function (handler) {
        this.onChange.Add(handler);
        return this;
    };
    FileInputV1.Name = "FileInput";
    FileInputV1.DefaultCssClasses = "";
    return FileInputV1;
}(ComponentBase_1.ComponentBase));
exports.FileInputV1 = FileInputV1;
var FileInput = /** @class */ (function (_super) {
    __extends(FileInput, _super);
    function FileInput(onChange) {
        var _this = _super.call(this, "input") || this;
        _this.onChange = new HandlersCollection_1.HandlersCollection();
        _this.Attribute("type", "file");
        _this.Class(FileInput.DefaultCssClasses);
        _this.onChange.Add(onChange);
        _this.element.addEventListener('change', function (e) {
            var _a;
            _this.onChange.Call((_a = _this.element.files) === null || _a === void 0 ? void 0 : _a[0], e);
        });
        return _this;
    }
    FileInput.prototype.OnChange = function (handler) {
        this.onChange.Add(handler);
        return this;
    };
    FileInput.Name = "FileInput";
    FileInput.DefaultCssClasses = "";
    return FileInput;
}(ComponentBase_1.ComponentBase));
exports.FileInput = FileInput;
