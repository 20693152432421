import { RawDeviceProfile } from "../Models/DeviceProfile";
import { DeviceBox } from "../Components/DeviceBox";
import { Server } from "../Services/Server";
import { Page } from "./Page";
import { StatusBar } from "../Components/StatusBar";
import { Button, Div, Line, Link, Ref, RefString, Snack, Span, TextInput } from "@tblabs/truffle";
import { Filter } from "../Models/Filter";
import { PasswordsManager } from "../Windows/PasswordsManager";
import { FilterSelect } from "../Components/FilterSelect";
import { Logger } from "../Components/Logger";
import { LogoutButton } from "../Components/LogoutButton";
import { AuthService } from "../Services/AuthService";
import { DevicesManager } from "../Services/DevicesManager";
import { RawDevicesWindow } from "../Windows/RawDevicesWindow";
import { DeviceConnectionStatus } from "../Models/DeviceConnectionStatus";


export class PanelPage extends Page
{
    private filter = new Ref<Filter>(Filter.Online).Storable("filter")
    private idFilter = new RefString().Storable("id-filter")
    private status = new Div().Text("Auto login...")
    private devicesBox = new Div().MarginTop(16)
    private filterSelector = new FilterSelect(this.filter).FloatLeft()
    private manager = new DevicesManager(this._server, this.status, this.filter, this.idFilter)
    private logger = new Logger();
    private _auth = new AuthService(this._server, this.logger)

    constructor(private _server: Server)
    {
        super("TX Admin " + _server.ConnectedTo, "PanelPage");

        this.header.Append(
            new LogoutButton(this._server))
        this.body.Append(
            this.logger)
        this.footer.Append(
            new StatusBar(this._server))
    }

    async OnAppend()
    {
        if (!await this._auth.Autologin())
        {
            return;
        }

        this.status.Text("Loading devices list...")

        this.body
            .Clear()
            .Append(
                this.filterSelector,
                new TextInput(this.idFilter).Width(120).Placeholder("ID filter").FloatLeft(),
                new Button("🍋 Refresh").Class("uk-button uk-button-primary").FloatLeft()
                    .OnClick(() => this.Refresh()),
                new Link("🔑Passwords").NoDecorationOnHover().FloatRight().Margin(8).MarginRight(16)
                    .OnClick(() => new PasswordsManager(this._server)),
                new Link("🥩Raw").FloatRight().Margin(8)
                    .OnClick(() => new RawDevicesWindow(this.manager.RawDevices)),
                new Line(),
                this.status,
                this.devicesBox,
                new Span(this._server.Id).Class("server-id")
            )

        this.filterSelector
            .OnChange(() =>
            {
                this.Refresh();
            });

        this._server
            .OnProblem(problem =>
            {
                console.log('Server message:', problem);
                window.location.hash = "login";
                window.location.reload();
            })
            .OnDevicesUpdate((rawDevicesProfiles: RawDeviceProfile[]) =>
            {
                this.manager.FromRaw(rawDevicesProfiles)

                this.devicesBox
                    .Clear()
                    .Append(
                        ...this.manager.Devices
                            .map(x => new DeviceBox(x)))
            })
            .OnNewDevice((rawDeviceProfile: RawDeviceProfile) =>
            {
                if (this.manager.Exist(rawDeviceProfile))
                {
                    return;
                }

                new Snack(rawDeviceProfile.Id, "🟢 Just connected!", { autoCloseTimeout: 5000, clickAnywhereToClose: true })

                const newDevice = this.manager.AddOneFromRaw(rawDeviceProfile)

                this.devicesBox.Append(
                    new DeviceBox(newDevice))
            })
            // .OnDeviceUpdate((status: DeviceConnectionStatus) =>
            // {

            // })
            .OnDeviceLost((tid: string) =>
            {
                new Snack(tid, "🔴 Just disconnected!", { autoCloseTimeout: 5000, clickAnywhereToClose: true })
            })
            .OnPictureUpdate((tid, pic, index, time) =>
            {
                this.manager.UpdatePicture(tid, pic)
            })
            .Connect(this.filter.value)
    }

    private Refresh()
    {
        this.devicesBox.Clear()
        this.status.Text(`Refreshing (fetching ${this.filter.value} devices)...`);
        this._server.Refresh(this.filter.value);
    }
}
