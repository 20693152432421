"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Root = void 0;
var _1 = require("../../Core/$");
var Div_1 = require("../Containers/Div");
var Root = /** @class */ (function (_super) {
    __extends(Root, _super);
    function Root(hook, divClass) {
        if (divClass === void 0) { divClass = ""; }
        var _a;
        var _this = _super.call(this, divClass) || this;
        (_a = (0, _1.$)(hook !== null && hook !== void 0 ? hook : Root.Hook)) === null || _a === void 0 ? void 0 : _a.Append(_this.Element);
        return _this;
    }
    Root.Hook = "body";
    return Root;
}(Div_1.Div));
exports.Root = Root;
