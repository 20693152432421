"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PasswordInput = void 0;
var RV_1 = require("../../Core/RV");
var Ref_1 = require("../../Core/Ref");
var Div_1 = require("../Containers/Div");
var Span_1 = require("../Containers/Span");
var TextInput_1 = require("./TextInput");
var PasswordInput = /** @class */ (function (_super) {
    __extends(PasswordInput, _super);
    function PasswordInput(passwordRef) {
        if (passwordRef === void 0) { passwordRef = new Ref_1.Ref(""); }
        var _this = _super.call(this, "") || this;
        _this.passwordRef = passwordRef;
        _this.passwordInput = new TextInput_1.TextInput(_this.passwordRef).Class(TextInput_1.TextInput.DefaultCssClasses);
        _this.showPasswordBtn = new Span_1.Span("👀").PositionAbsolute().Top(8).Right(6).Opacity(0.5).CursorPointer();
        _this.PositionRelative();
        _this.showPasswordBtn
            .On(['mousedown', 'touchstart'], function () {
            _this.passwordInput.Attribute("type", "text");
            _this.showPasswordBtn.Opacity(1);
        })
            .On(['mouseup', 'touchend'], function () {
            _this.passwordInput.Attribute("type", "password");
            _this.showPasswordBtn.Opacity(0.5);
        });
        _this.passwordInput.Attribute("type", "password");
        _this.Append(_this.passwordInput, _this.showPasswordBtn);
        return _this;
    }
    PasswordInput.prototype.Placeholder = function (placeholder) {
        var _this = this;
        RV_1.RV.Use(placeholder, function (v) { return _this.passwordInput.Placeholder(v); });
        return this;
    };
    PasswordInput.Name = "PasswordInput";
    return PasswordInput;
}(Div_1.Div));
exports.PasswordInput = PasswordInput;
