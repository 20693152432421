"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RV = void 0;
var Ref_1 = require("./Ref");
/*
    Ref or Value

    DLACZEGO TO NIE JEST ZASZYTE W KLASACH BAZOWYCH?????????????
*/
var RV = /** @class */ (function () {
    function RV() {
    }
    RV.Use = function (valueOrRef, callback) {
        if (valueOrRef == undefined) {
            // throw new Error("Value or Ref must be provided");
            return;
        }
        if (!callback) {
            throw new Error("Callback must be provided");
        }
        if (valueOrRef instanceof Ref_1.Ref) {
            valueOrRef.OnChange(function (v) {
                callback(v);
            }, true);
        }
        else {
            callback(valueOrRef);
        }
    };
    return RV;
}());
exports.RV = RV;
