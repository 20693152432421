"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.HashRef = void 0;
var Ref_1 = require("./Ref");
var HashRef = /** @class */ (function (_super) {
    __extends(HashRef, _super);
    function HashRef() {
        var _this = _super.call(this, window.location.hash.slice(1)) || this;
        _this.preventOnChange = false;
        window.onhashchange = function (e) {
            _this.preventOnChange = true;
            var hash = e.newURL.split("#")[1];
            _this.Set(hash, _this);
            _this.preventOnChange = false;
        };
        _this.OnChange(function (v) {
            if (_this.preventOnChange) {
                return;
            }
            window.location.hash = v;
        });
        return _this;
    }
    return HashRef;
}(Ref_1.Ref));
exports.HashRef = HashRef;
