import { Button, ModalWindow, Ref, TextInput } from "@tblabs/truffle";
import { Device } from "../Services/Device";


export class PasswordEditWindow extends ModalWindow
{
    constructor(device: Device)
    {
        super(`Edit ${device.profile.Id} password`, true, true, true);
        this.MaxWidth(460);

        const password = new Ref<string>(device.profile.Password.value);

        this.content.Append(new TextInput(password).Width(320));

        const button = new Button("Save & Exit").Class("uk-button uk-button-primary");
        
        this.footer.Append(button
            .OnClick(async () =>
            {
                button.Label("Saving...");
                if (await device.SetPassword(password.value))
                {
                    device.profile.Password.value = password.value;
                    this.CloseWindow();
                }
                else
                {
                    alert("Could not set new password :(");
                    button.Label("Try to save again");
                }
            }));

        this.OpenWindow();
    }
}
