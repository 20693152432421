import { Span, Ref } from "@tblabs/truffle";

export class PasswordLabel extends Span
{
    private hidden = true;

    constructor(ref: Ref<string>)
    {
        super();
        this.Class("password");

        ref.OnChange(v =>
        {
            this.Print(v);
        });

        this.Print(ref.value);

        this.OnClick(() =>
        {
            if (this.IsLocked(ref.value))
            {
                return;
            }
            if (this.hidden)
            {
                this.Text(ref.value);
                this.hidden = false;
            }
            else 
            {
                this.Text("(click to see) ..."+ref.value.substring(ref.value.length-3));
                this.hidden = true;
            }
        });
    }

    private Print(password: string)
    {
        if (!password)
        {
            this.Text("🟥 PASSWORD NEVER SET").Bold().Color("maroon");
            return;
        }
        if (this.IsLocked(password))
        {
            this.Text("◄ LOCKED ►").Color("maroon");
        }
        else if (this.hidden)
        {
            this.Text("(click to see) ..."+password?.substring(password.length-3)).Color("#222");
        }
        else if (!this.hidden)
        {
            this.Text(password).Color("#222");
        }
    }

    private IsLocked(password: string): boolean
    {
        return password?.endsWith("defpass");
    }
}
