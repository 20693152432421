import { DeviceConnectionStatus } from "../Models/DeviceConnectionStatus";
import { HotspotInfo } from "../Services/HotspotInfo";
import { HotspotType } from "../Services/HotspotType";
import { TooltipLabel } from "./TooltipLabel";

export class HotspotIcon extends TooltipLabel
{
    constructor(hotspot: HotspotInfo, connectionStatus: DeviceConnectionStatus)
    {
        if ([DeviceConnectionStatus.Unset, DeviceConnectionStatus.Offline].includes(connectionStatus))
        {
            super("", "")
        }
        else
        {
            let icon = "🔌";
            switch (hotspot.Type)
            {
                case HotspotType.Unknown: icon = "🔌"; break;
                case HotspotType.Home: icon = "🏠"; break;
                case HotspotType.Initial: icon = "🚀"; break;
                case HotspotType.Dedicated: icon = "🎵"; break;
                case HotspotType.Concealed: icon = "👓"; break;
                case HotspotType.Custom: icon = "🍹"; break;
            }

            super(icon, hotspot.Name || "Hotspot unknown");
            this.Class("HotspotIcon");
        }
    }
}
