"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RefString = void 0;
var Ref_1 = require("./Ref");
var RefString = /** @class */ (function (_super) {
    __extends(RefString, _super);
    function RefString(initialDate) {
        if (initialDate === void 0) { initialDate = ""; }
        return _super.call(this, initialDate, false) || this;
    }
    RefString.prototype.Includes = function (toCompare) {
        // return value.includes(this.value as string);
        return this.Compare(this, toCompare, function (a, b) { return a.includes(b); });
    };
    return RefString;
}(Ref_1.Ref));
exports.RefString = RefString;
