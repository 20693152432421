"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabeledSelect2 = exports.LabeledSelect = void 0;
var Div_1 = require("../Containers/Div");
var Select_1 = require("./Select");
var TextLabel_1 = require("./TextLabel");
var LabeledSelect = /** @class */ (function (_super) {
    __extends(LabeledSelect, _super);
    function LabeledSelect(label, ref, selectOptions) {
        if (selectOptions === void 0) { selectOptions = []; }
        var _this = _super.call(this, "tf-form-labeled-select") || this;
        _this.label = label;
        _this.ref = ref;
        _this.selectOptions = selectOptions;
        _this.labelCmp = new TextLabel_1.TextLabel(_this.label).Class("label");
        _this.input = new Select_1.Select(_this.ref, _this.selectOptions).Class("input");
        _this.Append(_this.labelCmp, _this.input);
        return _this;
    }
    LabeledSelect.prototype.Edit = function (hook) {
        hook(this.labelCmp, this.input);
        return this;
    };
    return LabeledSelect;
}(Div_1.Div));
exports.LabeledSelect = LabeledSelect;
var LabeledSelect2 = /** @class */ (function (_super) {
    __extends(LabeledSelect2, _super);
    function LabeledSelect2(label, ref, selectOptions) {
        if (selectOptions === void 0) { selectOptions = []; }
        var _this = _super.call(this, "tf-form-labeled-select") || this;
        _this.label = label;
        _this.ref = ref;
        _this.selectOptions = selectOptions;
        _this.labelCmp = new TextLabel_1.TextLabel(_this.label).Class("label").Width(320).DisplayInlineBlock();
        _this.Select = new Select_1.Select(_this.ref, _this.selectOptions).Class("input").Width(200);
        _this.Append(_this.labelCmp, _this.Select);
        return _this;
    }
    LabeledSelect2.prototype.Edit = function (hook) {
        hook(this.labelCmp, this.Select);
        return this;
    };
    return LabeledSelect2;
}(Div_1.Div));
exports.LabeledSelect2 = LabeledSelect2;
