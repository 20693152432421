import { ILogger } from "../Components/ILogger";
import { Server } from "./Server";


export class AuthService
{
    constructor(private _server: Server, private _logger: ILogger) { }

    public async Autologin(): Promise<boolean>
    {
        if (this._server.IsLoggedIn)
        {
            this._logger.Log("Already logged in.");
            return true;
        }

        this._logger.Log("Not logged in. Trying password from browser local storage...");

        const storedPassword = window.localStorage.getItem("password");

        if (!storedPassword)
        {
            this._logger.Log("Password not found in browser memory. Go to login page.");
            return false;
        }

        this._logger.Log("Found password in browser memory. Trying to login...");

        const result = await this._server.TryLogin(storedPassword);

        this._logger.Log(result.Authorized ? "Access granted" : result.Message);

        return result.Authorized;
    }
}
