
export function CopyToClipboard(textToCopy): Promise<string>
{
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext)
    {
        return new Promise((res: (message: string) => void, rej: (message: string) => void) => 
        {
            // navigator clipboard api method'
            navigator.clipboard.writeText(textToCopy);
            
            res("Success");
        }) 
    }
    else
    {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        return new Promise((res: (message: string) => void, rej: (message: string) => void) =>
        {
            // here the magic happens
            document.execCommand('copy') ? res("Copy to clipboard succeeded") : rej("Copy to clipboard failed");
            textArea.remove();
        });
    }
}