"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SimpleEmitter = void 0;
var SimpleEmitter = /** @class */ (function () {
    function SimpleEmitter() {
        // private handlersNames: string[] = [];
        this.handlers = [];
    }
    Object.defineProperty(SimpleEmitter.prototype, "Subs", {
        // public AddNamedHandler(name: string, callback: (val: any) => void)
        // {
        //     if (!this.handlersNames.includes(name))
        //     {
        //         this.handlersNames.push(name);
        //         this.handlers.push(callback);
        //     }
        // }
        get: function () {
            return this.handlers.length;
        },
        enumerable: false,
        configurable: true
    });
    SimpleEmitter.prototype.Clear = function () {
        this.handlers = [];
    };
    SimpleEmitter.prototype.AddHandler = function (callback) {
        this.handlers.push(callback);
    };
    SimpleEmitter.prototype.CallHandlers = function () {
        var val = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            val[_i] = arguments[_i];
        }
        this.handlers.forEach(function (h) { return h.apply(void 0, val); });
    };
    return SimpleEmitter;
}());
exports.SimpleEmitter = SimpleEmitter;
