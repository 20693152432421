import { Button, Label, ModalWindow, MultilineInput, Ref, Snack, TextInput } from "@tblabs/truffle";
import { Device } from "../../Services/Device";

export class FileEditWindow extends ModalWindow
{
    private loadButton = new Button("Load").Class("uk-button uk-button-primary").FloatLeft();
    private saveButton = new Button("Save").Class("uk-button uk-button-primary").FloatRight();

    constructor(_device: Device)
    {
        super("File editor");
        this.Width(96, "%");

        const fileDir = new Ref<string>("").Storable("file-dir");
        const fileContent = new Ref<string>("");
        const status = new Ref<string>("");

        _device
            .OnFileContentLoad(content =>
            {
                fileContent.value = content;
                status.value = "File loaded";
                this.loadButton.Label("Load");
            })
            .OnFileProblem(problem =>
            {
                status.value = problem;
                this.loadButton.Label("Load");
                this.saveButton.Label("Save");
            })
            .OnFileSaved(dir =>
            {
                status.value = "File saved";
                this.saveButton.Label("Save");
            })

        this.loadButton.OnClick(() =>
        {
            this.loadButton.Label("Loading...");
            _device.LoadFile(fileDir.value)
        });

        this.saveButton.OnClick(() =>
        {
            this.saveButton.Label("Saving...");
            _device.SaveFile(fileDir.value, fileContent.value)
        });

        this.content.Padding(16)
            .Append(new TextInput(fileDir).Width(100, "%").MarginBottom(8).Placeholder("File path"))
            .Append(new MultilineInput(fileContent).Rows(18).FullWidth().Placeholder("File Content"));
        this.footer.Padding(16).TextAlignCenter()
            .Append(this.loadButton)
            .Append(new Label(status).DisplayInlineBlock().MarginTop(8))
            .Append(this.saveButton)
    }
}
