import { Device } from "./Device";
import { DeviceProfile, RawDeviceProfile } from "../Models/DeviceProfile";
import { Server } from "./Server";
import { Ref } from "@tblabs/truffle";
import { Filter } from "../Models/Filter";


export class DevicesManager
{
    private rawDevices: RawDeviceProfile[] = [];
    public get RawDevices(): RawDeviceProfile[]
    {
        return this.rawDevices;
    }

    private devices: Device[] = [];
    public get Devices(): Device[]
    {
        return this.devices;
    }

    constructor(
        private _server: Server, 
        private status, 
        private filter: Ref<Filter>, 
        private idFilter: Ref<string>) 
    { }

    public FromRaw(rawDevicesProfiles: RawDeviceProfile[]): void
    {
        this.devices = []
        this.rawDevices = []
        this.rawDevices = rawDevicesProfiles;

        const profiles = this.rawDevices
            .map((d: RawDeviceProfile) => new DeviceProfile(d))
            .filter(d => this.idFilter.value ? d.Id.includes(this.idFilter.value) : true)
            .sort((a, b) => a.ConnectionStatus > b.ConnectionStatus ? 1 : -1)
            // .sort((a, b) => a.Id > b.Id ? 1 : -1)

        if (profiles.length == 0)
        {
            this.status.Text(`No ${this.filter.value} devices found`);
            return;
        }
        else
            this.status.Text("");

        this.devices = profiles.map(profile => new Device(profile, this._server));
    }

    public Exist(profile: RawDeviceProfile): boolean
    {
        return this.rawDevices.map(x => x.Id).includes(profile.Id);
    }

    public AddOneFromRaw(rawDeviceProfile: RawDeviceProfile): Device
    {
        this.rawDevices.push(rawDeviceProfile);

        const profile = new DeviceProfile(rawDeviceProfile)

        this.status.Text("");

        const device = new Device(profile, this._server);

        this.devices.push(device)

        return device;
    }

    public UpdatePicture(tid: string, pic: string): void
    {
        const dev = this.devices.find(x => x.profile.Id == tid);

        if (!dev) return;

        if (pic == "no-picture")
        {
            alert("No new picture");
            return;
        }

        dev.profile.Picture.value = pic;
    }
}
