import { Button, ModalWindow, MultilineInput, Ref, TextInput } from "@tblabs/truffle";
import { Storage } from "./Storage";


export class AddCommandWindow extends ModalWindow
{
    constructor(_storage: Storage)
    {
        super("Add new command");

        const label = new Ref<string>("");
        const cmd = new Ref<string>("");

        this.content
            .Append(new TextInput(label).Width(100, "%").MarginBottom(8).Placeholder("Description"))
            .Append(new MultilineInput(cmd).Rows(8).MarginBottom(8).Placeholder("Command"));
        this.footer
            .Append(
                new Button("Add").Class("uk-button uk-button-primary")
                    .OnClick(() =>
                    {
                        _storage.Add(label.value, cmd.value);
                        this.CloseWindow();
                    }));
    }
}
