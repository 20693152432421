import { HotspotType } from "./HotspotType";


export class HotspotInfo
{
    constructor(
        public Name: string,
        public Type: HotspotType
    ) { }
}
