"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RefDeflattener = void 0;
var Ref_1 = require("../../../Ref");
var Collection_1 = require("../../../Collection");
var CollectionTemplate_1 = require("./CollectionTemplate");
var ArrayTemplate_1 = require("./ArrayTemplate");
var RefDeflattener = /** @class */ (function () {
    function RefDeflattener() {
    }
    RefDeflattener.Deflat = function (source, target, options) {
        if (options === void 0) { options = { throw: true }; }
        if (target instanceof Ref_1.Ref) {
            if (target.value instanceof Date) {
                target.value = new Date(source);
            }
            else {
                target.value = source;
            }
        }
        else if (target instanceof Date) {
            target = new Date(source);
        }
        else if (target instanceof ArrayTemplate_1.ArrayTemplate) {
            target = source.map(function (x) { return RefDeflattener.Deflat(x, target.CreateFn(), options); });
        }
        else if (target instanceof Array) {
            if (target.length != 1) {
                if (options === null || options === void 0 ? void 0 : options.throw)
                    throw new Error("You need to define array type by putting one item into array template (otherwise it won't know to what type deserialize). You can disable this message by setting third parameter of this method to { options.throw: false }");
            }
            if (target[0] instanceof Date) {
                target = source.map(function (x) { return new Date(x); });
            }
            else if (target[0] instanceof Ref_1.Ref) {
                if (target[0].value instanceof Date) {
                    target = source.map(function (x) { return new Ref_1.Ref(x); });
                }
                else if (typeof target[0].value == "string") {
                    target = source.map(function (x) { return new Ref_1.Ref(x); });
                }
                else if (typeof target[0].value == "number") {
                    target = source.map(function (x) { return new Ref_1.Ref(x); });
                }
                else if (typeof target[0].value == "boolean") {
                    target = source.map(function (x) { return new Ref_1.Ref(x); });
                }
            }
            else if (target[0] instanceof Object) {
                target = source.map(function (x) { return RefDeflattener.Deflat(x, target[0], options); });
            }
            else {
                target = source;
            }
        }
        else if (target instanceof CollectionTemplate_1.CollectionTemplate) {
            target = new Collection_1.Collection(source.map(function (x) { return RefDeflattener.Deflat(x, target.CreateFn(), options); }));
        }
        else if (target instanceof Collection_1.Collection) {
            if (options === null || options === void 0 ? void 0 : options.throw)
                throw new Error("PLEASE USE CollectionTemplate INSTEAD! This approach will work only for Collection<Ref> or Collection<primitive>, not for Collection<Object>!");
            if (target.Items.length != 1) {
                if (options === null || options === void 0 ? void 0 : options.throw)
                    throw new Error("You need to define collection type by putting one item into collection (otherwise it won't know to what type deserialize). You can disable this message by setting third parameter of this method to { options.throw: false }");
            }
            if (target.Items[0] instanceof Ref_1.Ref) {
                if (target.Items[0].value instanceof Date) {
                    var items = source.map(function (x) { return new Ref_1.Ref(x); });
                    target.Load.apply(target, items);
                }
                else if (typeof target.Items[0].value == "string") {
                    var items = source.map(function (x) { return new Ref_1.Ref(x); });
                    target.Load.apply(target, items);
                }
                else if (typeof target.Items[0].value == "number") {
                    var items = source.map(function (x) { return new Ref_1.Ref(x); });
                    target.Load.apply(target, items);
                }
                else if (typeof target.Items[0].value == "boolean") {
                    var items = source.map(function (x) { return new Ref_1.Ref(x); });
                    target.Load.apply(target, items);
                }
            }
            else if (target.Items[0] instanceof Object) {
                if (options === null || options === void 0 ? void 0 : options.throw)
                    throw new Error("Sorry, but deserializing to Collection<Object> is not possible now. This is due to limitation of Javascript. Target like new Collection([new Foo()]) will always reference to the same Foo for every item of collection. You don't wanna this. You can disable this message by setting third parameter of this method to { options.throw: false }");
            }
            else {
                var items = source.map(function (x) {
                    if (x instanceof Ref_1.Ref) {
                        return x.value;
                    }
                    else {
                        return x;
                    }
                });
                target.Load.apply(target, items);
            }
        }
        else if (target instanceof Object) {
            Object.keys(target).forEach(function (k) {
                if (target[k] instanceof Date) {
                    target[k] = new Date(source[k]);
                }
                else if (target[k] instanceof Ref_1.Ref) {
                    if (target[k].value instanceof Date) {
                        target[k].value = new Date(source[k]);
                    }
                    else {
                        target[k].value = source[k];
                    }
                }
                else if (target[k] instanceof Object) {
                    target[k] = RefDeflattener.Deflat(source[k], target[k], options);
                }
                else {
                    target[k] = source[k];
                }
            });
        }
        else {
            target = source;
        }
        return target;
    };
    return RefDeflattener;
}());
exports.RefDeflattener = RefDeflattener;
