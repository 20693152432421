import { Span } from "@tblabs/truffle";


export class TooltipLabel extends Span
{
    constructor(text: string, info: string)
    {
        super();
        this.Text(text);
        this.Class("tooltip");

        this.Append(
            new Span(info).Class("tooltip-text")
        )
    }
}
