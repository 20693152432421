import { Button, Div, Link, MultilineInput, Ref, TextInput } from "@tblabs/truffle";
import { FileEditWindow } from "./FileEditWindow";
import { Storage } from "./Storage";
import { CommandManager } from "./CommandManager";
import { Device } from "../../Services/Device";

export class Terminal extends Div
{
    private terminalContent = new Ref<string>("");
    private commandToSend = new Ref<string>("ls");
    private terminal = new MultilineInput(this.terminalContent)
        .Readonly().Rows(18).Placeholder(`Shell output should be seen here.
⚠ Do not start interactive things (like nano etc)
⚠ Remember not to kill this process
⚠ Remember to call every sh with (cd {dir} && {script}.sh) if scripts contains relative paths`)
        .Style("width: 100%; background: #222; color: #eee; padding: 12px; font-family: Courier New");
    private commandInput = new TextInput(this.commandToSend)
        .Style("width: 75%; background: #222; color: #eee; padding: 12px; margin-top: 8px");
    private sendButton = new Button("Send")
        .Style("width: 24.5%; margin-left: 0.5%; margin-top: 8px").Class("uk-button uk-button-danger");

    constructor(private _device: Device, _storage: Storage)
    {
        super();

        this.commandInput.OnEnterKeyPressed(() => this.Send());
        this.sendButton.OnClick(() => this.Send());

        _device.OnShellResult(result => 
        {
            this.terminalContent.value += result + "\n";
            this.terminal.ScrollToBottom();
        });

        const fileEditWindow = new FileEditWindow(_device).HideOnClose();

        this.Append(
            new Button("📜 File editor", () => fileEditWindow.ShowWindow())
                .MarginBottom(8).Class("uk-button uk-button-primary")
                .FloatLeft(),
            this.terminal,
            this.commandInput,
            this.sendButton,
            new CommandManager(_storage)
                .WhenCommandSelected(cmd => this.commandToSend.value = cmd)
        );
    }

    private Send()
    {
        this.terminalContent.value += "> " + this.commandToSend.value + "\n";
        this._device.SendShellCommand(this.commandToSend.value);
    }
}
