"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RefBool = void 0;
var Ref_1 = require("./Ref");
var RefBool = /** @class */ (function (_super) {
    __extends(RefBool, _super);
    function RefBool(initialDate) {
        if (initialDate === void 0) { initialDate = false; }
        return _super.call(this, initialDate, false) || this;
    }
    RefBool.prototype.CombineRefBool = function (anotherRef, operation) {
        var _this = this;
        var result = new RefBool(operation(this.value, anotherRef.value));
        this.OnChange(function (v) { return result.value = operation(_this.value, anotherRef.value); });
        anotherRef.OnChange(function (v) { return result.value = operation(_this.value, anotherRef.value); });
        return result;
    };
    RefBool.prototype.CombineRefBoolValue = function (anotherValue, operation) {
        var result = new RefBool(operation(this.value, anotherValue));
        this.OnChange(function (v) { return result.value = operation(v, anotherValue); }, true);
        return result;
    };
    RefBool.prototype.MixRefBool = function (x, operation) {
        if (x instanceof Ref_1.Ref) {
            return this.CombineRefBool(x, operation);
        }
        else {
            return this.CombineRefBoolValue(x, operation);
        }
    };
    RefBool.prototype.And = function (anotherRef) {
        return this.MixRefBool(anotherRef, function (a, b) { return a && b; });
    };
    RefBool.prototype.Or = function (anotherRef) {
        return this.MixRefBool(anotherRef, function (a, b) { return a || b; });
    };
    RefBool.prototype.Toggle = function () {
        this.value = !this.value;
        return this;
    };
    RefBool.prototype.True = function () {
        this.value = true;
        return this;
    };
    RefBool.prototype.IsTrue = function () {
        return this.value == true;
    };
    RefBool.prototype.IsFalse = function () {
        return this.value == false;
    };
    RefBool.prototype.False = function () {
        this.value = false;
        return this;
    };
    return RefBool;
}(Ref_1.Ref));
exports.RefBool = RefBool;
