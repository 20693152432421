import { $, Div } from "@tblabs/truffle";

export class Page extends Div
{
    public header = new Div(this.cssClass + " header").Text(this.pageTitle);
    public body = new Div(this.cssClass + " body");
    public footer = new Div(this.cssClass + " footer");

    constructor(private pageTitle: string, private cssClass: string)
    {
        super();
        this.Class("page")

        $("title")?.Text(pageTitle);

        this.Append(
            new Div("content")
                .Append(
                    new Div("content-inside")
                        .Append(
                            this.header,
                            this.body,
                        )),
            this.footer
        );
    }
}
