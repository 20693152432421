"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JsonViewerConfig = void 0;
var JsonViewerConfig = /** @class */ (function () {
    function JsonViewerConfig() {
        this.foldAll = true;
        this.foldSymbol = "➕";
        this.unfoldSymbol = "➖";
        this.keyPrinter = function (key, objType) { return "◾ " + key + ":"; };
        this.arrayKeyPrinter = function (index, objType) { return "#" + index; };
        this.keyStyle = "color: #222";
        this.nullStyle = "color: #ff7f96";
        this.stringStyle = "color: green";
        this.numberStyle = "color: blue";
        this.boolStyle = "color: violet";
        this.typePrinter = function (t) { return "(" + t + ")"; };
        this.typeInfoStyle = "color: #aaa; font-style: italic;";
        this.maxStringLength = 100;
        this.indent = 22;
        this.showCopyIcon = true;
        this.copyValueIcon = "📋";
        this.openLinkButton = "🌐";
        this.markedColor = "yellow";
    }
    return JsonViewerConfig;
}());
exports.JsonViewerConfig = JsonViewerConfig;
