import { RawDeviceProfile } from "../Models/DeviceProfile";
import { JsonViewer, ModalWindow } from "@tblabs/truffle";


export class RawDevicesWindow extends ModalWindow
{
    constructor(devices: RawDeviceProfile[])
    {
        super("Raw devices state", true, true, true);
        this.MaxWidth(98, "%");

        this.Content(_ => _.Padding(8).MinHeight(300).MaxHeight(95, "%").OverflowYScroll()
            .Append(
                new JsonViewer(devices, undefined, { maxStringLength: 60 })))
            .OpenWindow();
    }
}
