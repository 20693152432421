"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegexExtractor = void 0;
var RegexExtractor = /** @class */ (function () {
    function RegexExtractor(regex) {
        this.regex = regex;
    }
    RegexExtractor.prototype.Extract = function (str) {
        try {
            var m = this.regex.exec(str);
            return (m === null || m === void 0 ? void 0 : m[1]) || "";
        }
        catch (error) {
            throw new Error(error);
        }
    };
    RegexExtractor.prototype.ExtractAll = function (str) {
        var m;
        var matches = [];
        while ((m = this.regex.exec(str)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === this.regex.lastIndex) {
                this.regex.lastIndex++;
            }
            // The result can be accessed through the `m`-variable.
            m.forEach(function (match, groupIndex) {
                if (groupIndex === 1) {
                    matches.push(match);
                }
            });
        }
        return matches;
    };
    return RegexExtractor;
}());
exports.RegexExtractor = RegexExtractor;
