import { Button, DestroyingPatternContentSwitcher, HashRef, ModalWindow, MultilineInput, NumberInput, Root, Select, Snack, TextInput } from "@tblabs/truffle";
import { LoginPage } from "./Pages/LoginPage";
import { ConfigProvider } from "./Services/ConfigProvider";
import { PanelPage } from "./Pages/PanelPage";
import { Server } from "./Services/Server";
import { PasswordsAutosaver } from "./Components/PasswordsAutosaver";

ModalWindow.DefaultCssClasses = "tf-modal-window";
TextInput.DefaultCssClasses = "uk-input";
NumberInput.DefaultCssClasses = "uk-input";
Button.DefaultCssClasses = "uk-button";
MultilineInput.DefaultCssClasses = "uk-textarea";
Select.DefaultCssClasses = "uk-select";
Snack.DefaultCssClasses = "tf-snack";


(async () =>
{
    console.log('NODE_ENV:', process.env.NODE_ENV);
    console.log('WATCHER_LOGIN_PAGE:', process.env.WATCHER_LOGIN_PAGE);
    console.log('WATCHER_WATCH_PAGE:', process.env.WATCHER_WATCH_PAGE);
    console.log('DROPBOX_PAGE:', process.env.DROPBOX_PAGE);
    console.log('TUTORIAL_PAGE:', process.env.TUTORIAL_PAGE);


    const root = new Root("body", "root");

    const hash = new HashRef();
    const _config = new ConfigProvider();
    const _server = new Server(_config);

    PasswordsAutosaver.Init()

    const pages = new DestroyingPatternContentSwitcher(hash).Class("page-switcher")
        .AddContent("panel", () => new PanelPage(_server))
        .AddContent("login", () => new LoginPage(_server, _config))
        .AddDefaultContent(() => new LoginPage(_server, _config))

    root.Append(pages)
})()

