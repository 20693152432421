"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeInput = void 0;
var Ref_1 = require("../../Core/Ref");
var RefComponentBase_1 = require("../../Core/RefComponentBase");
var TimeInput = /** @class */ (function (_super) {
    __extends(TimeInput, _super);
    function TimeInput(ref) {
        if (ref === void 0) { ref = new Ref_1.Ref(TimeInput.DefaultTime); }
        var _this = _super.call(this, "input", ref) || this;
        _this.Class("uk-input");
        _this.Attribute("type", "time");
        _this.element.addEventListener('change', function (e) {
            var _a;
            var v = e.target.value;
            var _b = v.split(":"), hour = _b[0], minute = _b[1];
            _this.ref.value = v;
            (_a = _this.onChange) === null || _a === void 0 ? void 0 : _a.call(_this, v, +hour, +minute);
        });
        _this.Refresh();
        return _this;
    }
    TimeInput.prototype.OnChange = function (callback) {
        this.onChange = callback;
        return this;
    };
    TimeInput.prototype.Refresh = function () {
        this.element.value = this.FormatTime(this.ref.value);
        if (this.showThatChanged)
            this.ref.HasChanged
                ? this.element.classList.add("tf-value-changed")
                : this.element.classList.remove("tf-value-changed");
    };
    TimeInput.prototype.AddTime = function (hours, minutes) {
        var _a;
        if (minutes === void 0) { minutes = 0; }
        var _b = this.Value.split(":").map(function (x) { return +x; }), hh = _b[0], mm = _b[1];
        hh = (hh + hours) % 24;
        hh < 0 ? hh = 23 : {};
        mm = (mm + minutes) % 60;
        mm < 0 ? mm = 59 : {};
        var v = hh + ":" + mm;
        this.Value = v;
        (_a = this.onChange) === null || _a === void 0 ? void 0 : _a.call(this, v, hh, mm);
        return this;
    };
    TimeInput.prototype.SetTime = function (hours, minutes) {
        var _a;
        if (minutes === void 0) { minutes = 0; }
        var v = hours + ":" + minutes;
        this.Value = v;
        (_a = this.onChange) === null || _a === void 0 ? void 0 : _a.call(this, v, hours, minutes);
        return this;
    };
    TimeInput.prototype.PadTo2Digits = function (num) {
        return num.toString().padStart(2, '0');
    };
    TimeInput.prototype.FormatTime = function (time) {
        var _a = time.split(":"), hour = _a[0], minute = _a[1];
        return ([
            this.PadTo2Digits(hour),
            this.PadTo2Digits(minute),
        ].join(':'));
    };
    TimeInput.Name = "TimeInput";
    TimeInput.DefaultTime = "12:00";
    return TimeInput;
}(RefComponentBase_1.RefComponentBase));
exports.TimeInput = TimeInput;
