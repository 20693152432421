import { PasswordSanitizer } from "../Components/PasswordSanitizer";


export class CustomerAccessMessageBuilder
{
    public static Sms(passwordWithSpaces: string, deviceId: string, serverId: string): string
    {
        let sms = `💻 Wejdź na stronę {watcher-login-page} i wpisz "{password}",  
lub kliknij ten link: {watch-page}

📱 Opcjonalnie w telefonie ustaw hotspot z nazwą "{deviceId}" i hasłem "{deviceId}password". 

🛟 Instrukcja obsługi: {tutorial-page}`;

        sms = CustomerAccessMessageBuilder.ReplaceParams(sms, serverId, deviceId, passwordWithSpaces);

        return sms;
    }

    public static Letter(passwordWithSpaces: string, deviceId: string, serverId: string): string
    {
        let msg = `Aby uruchomić samodzielnie kamerę skrzystaj z samouczka znajdującego się na stronie: {tutorial-page}

Następnie, kiedy na kamerze będzie już migać zielona dioda, wejdź na stronę:
{watcher-login-page} i wpisz hasło "{password}" lub kliknij ten link: {watch-page}

Opcjonalnie, dla bezpieczeństwa warto zmienić nazwę hotspota i jego hasło na "{deviceId}", z hasłem "{deviceId}password". Dzięki temu możemy mieć pewność, że nasza kamera połączy się tylko z naszym telefonem.

🛟 Pełna instrukcja obsługi, z którą warto się zapoznać jest dostępna na stronie {watcher-help-page}  

Życzymy powodzenia! 👍👍`;

        msg = CustomerAccessMessageBuilder.ReplaceParams(msg, serverId, deviceId, passwordWithSpaces);

        return msg;
    }

    private static ReplaceParams(txt: string, serverId: string, deviceId: string, passwordWithSpaces: string)
    {
        const sanitizedPassword = PasswordSanitizer.Sanitize(passwordWithSpaces);
        const watchPage = process.env.WATCHER_WATCH_PAGE
            ?.replace("{server}", serverId)
            ?.replace("{password}", sanitizedPassword);
        const loginPage = process.env.WATCHER_LOGIN_PAGE
            ?.replace("{server}", serverId);

        txt = txt
            .replace("{watcher-page}", process.env.WATCHER_PAGE || "🥔")
            .replace("{watcher-login-page}", loginPage || "🥔")
            .replace("{watch-page}", watchPage || "🥔")
            .replace("{password}", passwordWithSpaces || "🥔")
            .replace(/{deviceId}/g, deviceId || "🥔")
            .replace("{watcher-help-page}", process.env.WATCHER_HELP_PAGE || "🥔")
            .replace("{tutorial-page}", process.env.TUTORIAL_PAGE || "🥔")

        return txt;
    }
}
