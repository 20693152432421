import { Div } from "@tblabs/truffle";
import { DeviceConnectionStatus } from "../Models/DeviceConnectionStatus";
import { TooltipLabel } from "./TooltipLabel";


export class DeviceConnectionStatusIcon extends TooltipLabel
{
    constructor(status: DeviceConnectionStatus, isHalted: boolean)
    {
        const statuses = [
            [DeviceConnectionStatus.Unset, "🔘"],
            [DeviceConnectionStatus.Offline, "⚪"],
            [DeviceConnectionStatus.Connected, "🟢"],
            [DeviceConnectionStatus.ConnectError, "🟡"],
            [DeviceConnectionStatus.Disconnected, "🟤"],
        ];

        const dot = status == DeviceConnectionStatus.Connected && isHalted
            ? "🔵"
            : statuses[status || DeviceConnectionStatus.Unset][1] || "🔘";

        let statusDescription = "Unset";
        switch (status)
        {
            case DeviceConnectionStatus.Unset: statusDescription = "Unset"; break;
            case DeviceConnectionStatus.Offline: statusDescription = "Offline"; break;
            case DeviceConnectionStatus.Connected: statusDescription = "Connected"; break;
            case DeviceConnectionStatus.ConnectError: statusDescription = "Error"; break;
            case DeviceConnectionStatus.Disconnected: statusDescription = "Disconnected"; break;
        }

        super(dot as string, statusDescription + (isHalted ? " and halted" : ""));
        this.Class("DeviceConnectionStatusIcon");
    }
}
