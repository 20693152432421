import { Ref } from "@tblabs/truffle";


export class CommandEntry
{
    public Label = new Ref<string>("");
    public Command = new Ref<string>("");

    constructor(label: string, command: string)
    {
        this.Label.value = label;
        this.Command.value = command;
    }
}
