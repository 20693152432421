"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonTabs = void 0;
var Ref_1 = require("../../Core/Ref");
var Div_1 = require("../Containers/Div");
var Button_1 = require("../Elements/Button");
var DestroyingContentSwitcher_1 = require("./Core/DestroyingContentSwitcher");
var RadioSwitchesBuilder_1 = require("./Core/RadioSwitchesBuilder");
var ButtonTabs = /** @class */ (function (_super) {
    __extends(ButtonTabs, _super);
    function ButtonTabs(ref) {
        if (ref === void 0) { ref = new Ref_1.Ref(""); }
        var _this = _super.call(this) || this;
        _this.ref = ref;
        _this.switches = new RadioSwitchesBuilder_1.RadioSwitchesBuilder(_this.ref);
        _this.content = new DestroyingContentSwitcher_1.DestroyingContentSwitcher(_this.ref);
        _this.Append(_this.switches);
        _this.Append(_this.content);
        return _this;
    }
    ButtonTabs.prototype.Add = function (label, content) {
        this.switches.AddSwitch(label, new Button_1.Button(label), "uk-button-danger");
        this.content.AddContent(label, content);
        return this;
    };
    return ButtonTabs;
}(Div_1.Div));
exports.ButtonTabs = ButtonTabs;
