import { Ref } from "@tblabs/truffle";
import { DeviceConfig } from "./DeviceConfig";
import { DeviceConnectionStatus } from "./DeviceConnectionStatus";
import { RegexExtractor } from "../Components/RegexExtractor";
import { PictureConfig } from "./PictureConfig";
export class Stats
{
    public PicturesCount = 0;
    public PicturesSize = 0;
    public RebootsCount = 0;
}

export class Picture
{
    constructor(
        public Img: string,
        public IndexFromDevice: number,
        public PictureTime: Date)
    { }
}

export class RawDeviceProfile
{
    public Id: string;
    public ConnectionStatus: DeviceConnectionStatus;
    public Password: string;
    public WatchersCount: number;
    public Config: DeviceConfig;
    public Stats: Stats;
    public Picture: Picture;
}

export class DeviceProfile
{
    public Id: string;
    public ConnectionStatus: DeviceConnectionStatus;
    public Password = new Ref<string>("");
    public WatchersCount: number;
    public PicturesCount: number;
    public RebootsCount: number;
    public Picture = new Ref<string>("");
    public Config: DeviceConfig;
    public LastPictureTime: Date;

    constructor(raw: RawDeviceProfile)
    {
        this.Id = raw.Id;
        this.ConnectionStatus = raw.ConnectionStatus;
        this.Password.value = raw.Password;
        this.WatchersCount = raw.WatchersCount;
        this.PicturesCount = raw.Stats.PicturesCount;
        this.RebootsCount = raw.Stats.RebootsCount;
        this.Picture.value = raw.Picture?.Img;
        this.LastPictureTime = new Date(raw.Picture.PictureTime)
        this.Config = raw.Config;
    }

    public get IsLocked(): boolean
    {
        return this.Password.value.endsWith("defpass");
    }

    public get PictureConfig(): PictureConfig
    {
        return this.Config.picture;
    }
}
