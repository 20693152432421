import { Div, ModalWindow, NumberInput } from "@tblabs/truffle";
import { Storage } from "./Storage";
import { Device } from "../../Services/Device";
import { Terminal } from "./Terminal";

export class TerminalWindow extends ModalWindow
{
    constructor(device: Device)
    {
        super("💻 " + device.profile.Id + " Terminal");
        this.WidthPercent(98).HeightPercent(98);

        this.content.Padding(16)//.HeightPercent(110)
            .Append(
                new Terminal(device, new Storage()));

        this.footer = new Div();

        this.OpenWindow();
    }
}
