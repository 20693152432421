"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClickableImage = void 0;
var ComponentBase_1 = require("../../Core/ComponentBase");
var Image_1 = require("./Image");
var ClickableImage = /** @class */ (function (_super) {
    __extends(ClickableImage, _super);
    function ClickableImage(src, url, alt) {
        var _this = _super.call(this, "a") || this;
        _this.CursorPointer();
        _this.image = new Image_1.Image(src);
        _this.Append(_this.image);
        if (url)
            _this.Url(url);
        if (alt)
            _this.Alt(alt);
        return _this;
    }
    ClickableImage.prototype.Src = function (src) {
        return this.Attribute("src", src);
    };
    ClickableImage.prototype.Url = function (url) {
        return this.Attribute("href", url);
    };
    ClickableImage.prototype.Alt = function (description) {
        return this.Attribute("alt", description);
    };
    ClickableImage.Name = "ClickableImage";
    return ClickableImage;
}(ComponentBase_1.ComponentBase));
exports.ClickableImage = ClickableImage;
