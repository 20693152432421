"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadResult = void 0;
var UploadResult = /** @class */ (function () {
    function UploadResult(IsSuccess, UploadedFileDir, ErrorMessage) {
        if (IsSuccess === void 0) { IsSuccess = false; }
        if (UploadedFileDir === void 0) { UploadedFileDir = ""; }
        if (ErrorMessage === void 0) { ErrorMessage = ""; }
        this.IsSuccess = IsSuccess;
        this.UploadedFileDir = UploadedFileDir;
        this.ErrorMessage = ErrorMessage;
    }
    UploadResult.Success = function (dir) {
        return new UploadResult(true, dir, "");
    };
    UploadResult.Failure = function (reason) {
        return new UploadResult(false, "", reason);
    };
    return UploadResult;
}());
exports.UploadResult = UploadResult;
