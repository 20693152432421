import { Ref } from "@tblabs/truffle";
import { ServerInfo } from "./ServerInfo";

export class ConfigProvider
{
    public ServersPool: ServerInfo[] = [
        { Id: "H1", Name: "🌻 Server H1", Addr: "https://transcorder-server.herokuapp.com" },
        { Id: "H2", Name: "🌻 Server H2", Addr: "https://snapcam-c63944959af1.herokuapp.com" },
        { Id: "A1", Name: "🌹 Server A1", Addr: "https://transcorderserver.azurewebsites.net" },
        { Id: "S1", Name: "🌷 Server S1", Addr: "https://camera.specteam.pl:5005" },
        { Id: "R1", Name: "🌼 Server R1", Addr: "http://radio2.specteam.pl" },
        { Id: "L1", Name: "🪴 Server L1", Addr: "http://localhost:5005" },
    ];

    constructor(serverId?: string)
    {
        if (!serverId)
        {
            return;
        }

        const serverFromUrl = this.ServersPool.find(x => x.Id == serverId);
        if (serverFromUrl)
        {
            this.CurrentServerAddr.value = serverFromUrl.Addr;
        }
    }

    public CurrentServerAddr = new Ref<string>(this.ServersPool[0].Addr).Storable("admin-server-addr");

    public get CurrentServerName(): string
    {
        return this.ServersPool.find(x => x.Addr == this.CurrentServerAddr.value)?.Name || "unknown server";
    }

    public get CurrentServerId(): string
    {
        return this.ServersPool.find(x => x.Addr == this.CurrentServerAddr.value)?.Id || "unknown server";
    }
}
