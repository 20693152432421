import { Div, Span } from "@tblabs/truffle";
import { Server } from "../Services/Server";


export class StatusBar extends Div
{
    private statusDisplay = new Span("...").MarginTop(2);
    private heartbeatDisplay = new Span("").FloatRight().Color("#aaa");
    private historyBox = new Div();

    private SecondsToHHMMSS(sekundy: number): string
    {
        let h = Math.floor(sekundy / 3600);
        let m = Math.floor((sekundy % 3600) / 60);
        let s = sekundy % 60;

        const hh = (h < 10) ? "0" + h : h;
        const mm = (m < 10) ? "0" + m : m;
        const ss = (s < 10) ? "0" + s : s;

        return hh + ":" + mm + ":" + ss;
    }

    constructor(private _server: Server)
    {
        super();
        this
            .Background("#222").Color("#f8f8f8")
            .Padding(8, 10)
            .Width(100, "%")
            .Cursor("pointer");

        let history: string[] = [];

        this.historyBox.Hide();

        this.OnClick(() => this.historyBox.Toggle());

        this._server
            .OnStatusChange((message) =>
            {
                history = history.slice(-16);
                history.push(message);

                this.historyBox.Clear();

                history.forEach((e, i) =>
                {
                    if (i < history.length - 1)
                        this.historyBox.Append(new Div().BorderStyling("left", 4, "#444").PaddingLeft(6).Text(e));
                });

                this.statusDisplay.Text(message);
            })
            .OnHeartbeat(heartbeat =>
            {
                const heart = heartbeat.Counter % 2 ? '❤' : ' ';
                const timer = this.SecondsToHHMMSS(heartbeat.Counter);
                this.heartbeatDisplay.Text(`${heart} ${timer}`)
            });

        this.Append(
            this.historyBox,
            new Div()
                .Append(
                    this.statusDisplay,
                    this.heartbeatDisplay));
    }
}
