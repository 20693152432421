"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateTimeInput = void 0;
var Ref_1 = require("../../Core/Ref");
var RefComponentBase_1 = require("../../Core/RefComponentBase");
var DateTimeInput = /** @class */ (function (_super) {
    __extends(DateTimeInput, _super);
    function DateTimeInput(ref) {
        var _this = this;
        if (ref instanceof Ref_1.Ref) {
            _this = _super.call(this, "input", ref) || this;
        }
        else if (ref instanceof Date) {
            _this = _super.call(this, "input", new Ref_1.Ref(ref)) || this;
        }
        else if (typeof ref === "number") {
            _this = _super.call(this, "input", new Ref_1.Ref(new Date(ref))) || this;
        }
        else {
            _this = _super.call(this, "input", new Ref_1.Ref(new Date())) || this;
        }
        _this.Class("uk-input");
        _this.Attribute("type", "datetime-local");
        // this.Attribute("type", "datetime");
        _this.element.addEventListener('change', function (e) {
            var v = e.target.value;
            // console.log('onchange', v)
            _this.ref.value = new Date(v);
        });
        _this.Refresh();
        return _this;
    }
    DateTimeInput.prototype.Refresh = function () {
        // Uzyskanie aktualnej daty i godziny w czasie uniwersalnym (UTC)
        var t = this.ref.value;
        // const tzoh = new Date().getTimezoneOffset() / 60;
        // console.log('TZO', tzoh)
        var year = t.getFullYear();
        var month = t.getMonth() + 1; // Pamiętaj, że miesiące są indeksowane od 0
        var day = t.getDate();
        // console.log(t.getHours(), tzoh)
        var hours = t.getHours(); //- tzoh;
        var minutes = t.getMinutes();
        // // Formatowanie daty i godziny do ISO string
        var dateWithoutTimezone = "".concat(year, "-").concat(month.toString().padStart(2, '0'), "-").concat(day.toString().padStart(2, '0'), "T").concat(hours.toString().padStart(2, '0'), ":").concat(minutes.toString().padStart(2, '0'));
        // console.log('CCCCCCCCc', currentISOString)
        this.element.value = dateWithoutTimezone;
        // console.log('REFR1', this.ref.value)
        // // console.log('REFR iso', this.ref.value.toISOString())
        // // console.log('REFR utc', this.ref.value.toUTCString())
        // console.log('REFR iii', currentISOString)
        // this.element.value =currentISOString// this.ref.value.toISOString().slice(0, 16);
        // console.log('REFR', this.element.value)
        if (this.showThatChanged)
            this.ref.HasChanged
                ? this.element.classList.add("tf-value-changed")
                : this.element.classList.remove("tf-value-changed");
    };
    DateTimeInput.Name = "DateTimeInput";
    return DateTimeInput;
}(RefComponentBase_1.RefComponentBase));
exports.DateTimeInput = DateTimeInput;
