"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RefComponentBase = void 0;
var ComponentBase_1 = require("./ComponentBase");
var RefComponentBase = /** @class */ (function (_super) {
    __extends(RefComponentBase, _super);
    function RefComponentBase(baseElement, ref) {
        var _this = _super.call(this, baseElement) || this;
        _this.ref = ref;
        _this.showThatChanged = RefComponentBase.ShowThatChanged;
        _this.IsRefComponent = true;
        ref.AddSubscriber(_this);
        return _this;
    }
    Object.defineProperty(RefComponentBase.prototype, "Value", {
        get: function () {
            return this.ref.value;
        },
        set: function (value) {
            this.ref.value = value;
        },
        enumerable: false,
        configurable: true
    });
    RefComponentBase.prototype.SetValue = function (value) {
        if (!value)
            return this;
        this.ref.value = value;
        // this.Refresh()
        return this;
    };
    Object.defineProperty(RefComponentBase.prototype, "Ref", {
        get: function () {
            return this.ref;
        },
        enumerable: false,
        configurable: true
    });
    RefComponentBase.prototype.ShowThatChanged = function (showOrNot) {
        if (showOrNot === void 0) { showOrNot = true; }
        this.showThatChanged = showOrNot;
        return this;
    };
    RefComponentBase.prototype.Dispose = function () {
        // this.LOG(`💥 Disposing ${this.ID}...`);
        this.ref.RemoveControl(this);
    };
    RefComponentBase.ShowThatChanged = false;
    return RefComponentBase;
}(ComponentBase_1.ComponentBase));
exports.RefComponentBase = RefComponentBase;
