export enum Filter
{
    All = "all",
    Online = "online", // in connected state
    Locked = "locked", // default password
    Unlocked = "unlocked",
    Cold = "cold", // in disconnecting or error state
    Priv = "priv",
    Rental = "rental",
    Offline = "offline",
}