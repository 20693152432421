import { Button, Div, Label, Link, PasswordInput, Ref, Span } from "@tblabs/truffle";
import { Server } from "../Services/Server";
import { Page } from "./Page";
import { ConfigProvider } from "../Services/ConfigProvider";
import { ServerSelector } from "../Components/ServerSelector";

export class LoginPage extends Page
{
    private password = new Ref<string>("").Storable("admin-password");
    private loginButton = new Button("LOGIN").Class("btn half");
    private loginStatus = new Ref<string>("");
    private statusBox = new Label(this.loginStatus).Class("info").Hide();

    constructor(
        private _server: Server,
        private _config: ConfigProvider)
    {
        super("TX Admin", "LoginPage");

        _server.Logout()
    }

    protected async OnAppend(): Promise<void>
    {
        const passwordInput = new PasswordInput(this.password)
            .Placeholder("Case and spacing are insignificant")
            .OnEnterKeyPressed(async () => await this.TryLogin())
            .OnKeyDown(() => this.statusBox.Hide());

        this.loginButton
            .OnClick(async () => await this.TryLogin());

        this.body
            .MarginAuto()
            .PaddingTop(32)
            .TextAlignCenter()
            .Append(
                new Div("row").TextAlignLeft().MarginBottom(4)
                    .Append(new Span("PASSWORD").Class("title")),
                new Div("row").PositionRelative()
                    .Append(passwordInput),
                new Div("row").MarginTop(6)
                    .Append(this.loginButton.Class("uk-button-primary").FloatRight()),
                new Div("row")
                    .Append(this.statusBox),
                new Div("row").FullWidth().Left(0).PositionAbsolute().Bottom(68).TextAlignCenter()
                    .Append(
                        new ServerSelector(this._config),
                    ),
            );
        this.footer.Append(
            new Span(`CameraV3.AdminV2 @ ${process.env.NODE_ENV}`),
        )
    }

    private async TryLogin()
    {
        if (this.password.value == "")
        {
            this.statusBox.Show();
            this.loginStatus.value = "No password provided";
            return;
        }

        this.loginButton.Label("Trying to login...");

        const timer = setTimeout(() =>
        {
            this.statusBox.Show();
            this.loginStatus.value = "Authorization timeout. Try again later";
            this.loginButton.Label("Try again");
        }, 15000);

        const result = await this._server.TryLogin(this.password.value.trim());
        clearTimeout(timer);

        if (result.Authorized === true)
        {
            window.location.hash = "panel";
        }
        else
        {
            this.statusBox.Show();
            this.loginStatus.value = result.Message || "Authorization problem";
            this.loginButton.Label("Try again");
        }
    }
}
