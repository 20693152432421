import { Div, Link, Select, SelectOption } from "@tblabs/truffle";
import { ConfigProvider } from "../Services/ConfigProvider";

export class ServerSelector extends Div
{
    private pinger = new Link("🏓").NoDecorationOnHover().OnClick(() => this.Ping());

    constructor(private _config: ConfigProvider)
    {
        super();
        this.DisplayInlineBlock();

        this.Append(
            new Select(_config.CurrentServerAddr, _config.ServersPool.map(x => new SelectOption(x.Name, x.Addr)))
                .Width(132).Background("#f8f8f8")
                .OnChange(() => this.Ping()),
            this.pinger.MarginLeft(8)
        );

        this.Ping();
    }

    private async Ping()
    {
        try
        {
            this.pinger.Text("🚀");
            const res = await fetch(this._config.CurrentServerAddr.value + "/ping");
            const con = await res.text();
            this.pinger.Text(con == "pong" ? "✅" : "💔");
        }
        catch (ex)
        {
            this.pinger.Text("💔");
        }
    }
}
