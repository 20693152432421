"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dumper = void 0;
var Collection_1 = require("../Collection");
var Ref_1 = require("../Ref");
var Dumper = /** @class */ (function () {
    function Dumper() {
    }
    Dumper.Dump = function (obj) {
        var out = "";
        // obj.forEach(o=>  DumpObj(obj, o));
        out = Dumper.DumpObj2(obj, out);
        // console.log(out);
        return out;
    };
    Dumper.DumpObj2 = function (obj, out, prefix) {
        // console.log('DumpObj2');
        // if (prefix)
        //     out += prefix
        // out += `\n[[[[[${typeof obj}]]]]]\n`
        switch (typeof obj) {
            case "string":
                out += ":string";
                break;
            case "number":
                out += ":number";
                break;
            case "boolean":
                out += ":boolean";
                break;
            case "object":
                if (obj instanceof Ref_1.Ref) {
                    out += obj === null || obj === void 0 ? void 0 : obj.ToString();
                }
                else if (obj instanceof Collection_1.Collection) {
                    out += obj === null || obj === void 0 ? void 0 : obj.toString();
                    obj.Items.forEach(function (e, i) {
                        out += "\n[".concat(i, "]\n");
                        var cc = "";
                        cc = Dumper.DumpObj2(e, cc);
                        out += cc;
                    });
                }
                else {
                    Object.keys(obj).forEach(function (k, i) {
                        out += "__ ".concat(k);
                        var v = obj[k];
                        // switch (typeof v)
                        // {
                        //     case "string": out += `:string = "${v}"`; break;
                        //     case "number": out += `:number = ${v}`; break;
                        //     case "boolean": out += `:boolean = ${v ? "true" : "false"}`; break;
                        // default: out += `:unknown-type = ${v}`; break;
                        // }
                        out += ": ".concat(v);
                        out += "\n";
                        // out += DumpObj2(obj[k], out)
                    });
                    // out += "???";
                }
                break;
            default: out += "unknown type: " + typeof (obj);
        }
        // console.log('DumpObj2 end');
        return out;
    };
    Dumper.DumpObj = function (obj, out) {
        // console.log(obj);
        // if ((typeof obj === "string") || (typeof obj === "number")
        // || (typeof obj === "boolean"))
        // {
        //     out += `${k}: ${v || "(empty)"}\n`;
        // }
        // else
        // out+=typeof(obj) + ": "
        var _this = this;
        Object.keys(obj).forEach(function (k, i) {
            var v = obj[k];
            // console.log(k, typeof v);
            out += "🔸 ";
            // console.log(`#${i} k=${k} v=${v}`, 'isarr', Array.isArray(v));
            if ((typeof v === "string") || (typeof v === "number")) {
                out += "".concat(k, ": ").concat(v || "(empty)", "\n");
            }
            else if (v instanceof Ref_1.Ref) {
                // console.log(`REF ${k}: ${v.value}`);
                if (typeof (v.value) === "boolean") {
                    // console.log('V IS BOOL');
                    out += "".concat(k, ":bool : ").concat(v.value ? "true" : "false", " ").concat(v.HasChanged ? "*" : "", " [").concat(_this.WatchersInfo(v), "]\n");
                }
                else if (typeof (v.value) === "string") {
                    out += "".concat(k, ":string : \"").concat(v.value, "\" ").concat(v.HasChanged ? "*" : "", " [").concat(_this.WatchersInfo(v), "]\n");
                }
                else if (typeof (v.value) === "number") {
                    out += "".concat(k, ":number : ").concat(v.value, " ").concat(v.HasChanged ? "*" : "", " [").concat(_this.WatchersInfo(v), "]\n");
                }
                else {
                    // console.log('V IS NOT BOOL', typeof(v.value));
                    out += "".concat(k, " (").concat(typeof (v.value), "): ").concat(v.value || "(empty)", " ").concat(v.HasChanged ? "*" : "", " [").concat(_this.WatchersInfo(v), "]\n");
                }
            }
            else if (v instanceof Ref_1.Ref) {
                // console.log(`REF ${k}: ${v.value}`);
                out += "".concat(k, ": ").concat(v.value || "(empty)", " ").concat(v.HasChanged ? "*" : "", " [").concat(_this.WatchersInfo(v), "]\n");
            }
            else if (v instanceof Collection_1.Collection) {
                // console.log('ARRAY');
                // out += "" + k + ": " + this.Dump(v)+"\n";
                out += "" + k + ":collection(" + v.Count + ")\n";
                out += "___" + _this.DumpObj(v.Items, out);
            }
            else if (Array.isArray(v)) {
                // console.log('ARRAY');
                // out += "" + k + ": " + this.Dump(v)+"\n";
                // out += "" + k + ": " + Dump(v);
                out += "" + k + ":array(" + v.length + ")\n";
                v.forEach(function (e, i) {
                    out += "___ [" + i + "]: " + _this.DumpObj(e, out);
                });
            }
            else if (Object.keys(v).length > 0) // jeśli string to tu wlezie
             {
                // console.log('OBJ');
                // out += k+": "+this.Dump(v)+"\n";
                /////////  out += Dump(v);
            }
            else {
                // console.log('?????', k);  
                out += "" + k + ":" + (typeof v) + "\n";
            }
        });
        out += "\n";
        // console.log(out);
        return out;
    };
    Dumper.WatchersInfo = function (v) {
        return v.watchers.map(function (c) { return c.Id; }).join(", ") || "no watchers";
    };
    return Dumper;
}());
exports.Dumper = Dumper;
