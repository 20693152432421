import { Button, ModalWindow, Ref, TextInput } from "@tblabs/truffle";
import { Device } from "../Services/Device";
import { PasswordSanitizer } from "../Components/PasswordSanitizer";


export class AdminWatchWindow extends ModalWindow
{
    private OpenWatcherPage(device: Device, storageName: string, watcherEnv?: string)
    {
        if (!device.profile.Password.value) 
        {
            alert("Set password first!")
            return;
        }
        const password = PasswordSanitizer.Sanitize(device.profile.Password.value);
        const storage = "__" + storageName; // Those __ are to prevent user accidental general storage open 
        const watcherUrl = watcherEnv?.replace("{server}", device.Server.Id).replace("{password}", password).replace("{storage}", storage);
        const url = (watcherUrl || "");
        window.open(url);
    }

    constructor(device: Device)
    {
        super(`Admin watch for ${device.profile.Id}`, true, true);
        this.MaxWidth(420);

        const storageName = new Ref<string>("general").Storable("storage");

        this.content.Append(
            "Storage for all Watchers:",
            new TextInput(storageName).Width(200).MarginLeft(8));

        const localStageButton = new Button("LOCAL").Class("uk-button uk-button-default")
            .OnClick(async () =>
            {
                this.OpenWatcherPage(device, storageName.value, process.env.WATCHER_LOGIN_PAGE__LOCAL)
            });
            
            const openStageButton = new Button("STAGE").Class("uk-button uk-button-default")
            .OnClick(async () =>
            {
                this.OpenWatcherPage(device, storageName.value, process.env.WATCHER_LOGIN_PAGE__STAGE)
            });
            
            const openProdButton = new Button("PROD").Class("uk-button uk-button-primary")
            .OnClick(async () =>
            {
                this.OpenWatcherPage(device, storageName.value, process.env.WATCHER_LOGIN_PAGE__PROD)
            });

        this.footer.Append(localStageButton, openStageButton, openProdButton);

        this.OpenWindow();
    }
}
